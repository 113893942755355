<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
    <div v-if="type == 2" class="box center mB10" style="padding-left: 110px">
      <div class="fs16" style="color: #fff; margin-right: 40px">分数</div>
      <el-input v-model="fenshu"></el-input>
    </div>
    <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="180px">
      <!-- <el-form-item label="文件标题" prop="title">
              <el-input placeholder="请输入文件标题" v-model="ruleForm.title" />
            </el-form-item> -->
      <el-form-item label="文件附件" prop="file_arr">
        <el-upload action=" " multiple :limit="this.limitNum" :file-list="fileListAdd" :on-remove="handleAddRemove" :on-change="handleAddChange" :on-exceed="handleExceed" :auto-upload="false">
          <el-button v-show="!hideUploadAdd" size="small" type="primary" class="select-btn">选择</el-button>
          <span slot="tip" class="el-upload__tip">上传文件不超过{{ this.limitSize }}M</span>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item label="分数" prop="file_arr">
          <el-input v-model="fenshu"></el-input>
        </el-form-item> -->

      <el-form-item>
        <span class="sureBtn back-btn pointer mR30" @click="back">取消</span>
        <span class="backBtn submit-btn pointer" @click="submitForm">确定</span>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: {
    uploadUrl: {
      // 文件上传路径
      type: String,
      default: ""
    },
    limitSize: {
      ///  文件大小限制
      type: Number,
      default: 50
    },
    limitNum: {
      // 文件个数限制
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      dialogVisible: true,
      hideUploadAdd: false,
      ruleForm: { title: "", file_arr: [] },
      rules: {
        // title: [
        //   { required: true, message: "请输入文件标题", trigger: "blur" },
        //   { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        // ],
        file_arr: [{ type: "array", required: true, message: "请选择文件", trigger: "blur" }]
      },
      type: -1,
      fileListAdd: []
    };
  },
  methods: {
    handleClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.fileListAdd = [];
      });
      //   this.dialogVisible = false;
      this.$emit("close");
    },
    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < this.limitSize;
      if (!isLt20M) {
        this.$message.error("文件太大了!");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
        this.ruleForm.file_arr = this.fileListAdd;
        this.$refs.ruleForm.validate((valid) => {});
      }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= this.limitNum) {
        this.hideUploadAdd = true;
      }
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.hideUploadAdd = false;
      this.handleAddChange(file, fileList);
    },

    //返回
    back() {
      this.handleClose();
    },
    // 确定新增
    submitForm() {
      this.ruleForm.file_arr = this.fileListAdd;

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: "正在加载...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let formData = new FormData();
          // formData.append("title", this.ruleForm.title);
          this.fileListAdd.map((item) => {
            formData.append("file", item.raw);
          });

          // this.$axios.post(`${this.baseURL}sys/sysFile/${this.up}/import?bizId=${this.projectId}&originalName=${this.ruleForm.title}`, formData).then((res) => {
          this.$axios.post(`${this.baseURL}${this.uploadUrl}`, formData).then((res) => {
            this.loadingPage.close();
            console.log("文件上传", res);
            res = res.data;
            if (res.errCode == 200) {
              this.$message({
                message: "上传成功",
                type: "success"
              });
            } else {
              this.$message({
                message: res.errMsg,
                type: "error"
              });
            }
            this.$emit("success", res);
          });
        }
      });
    },

    beforeUpload(file) {
      console.log("file", file);
      if (file) {
        this.newFile.append("file", file); //  2. 上传之前，拿到file对象，并将它添加到刚刚定义的FormData对象中。
        console.log(this.newFile.get("file"));
        // return false
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;
}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}

::v-deep .el-table th.el-table__cell > .option-btn.cell {
  padding-right: 50px;
  box-sizing: border-box;
}

.down-img {
  width: 100%;
}

.main ::v-deep .el-dialog__wrapper div.pdf-class {
  width: 1200px !important;
  margin-top: 0px !important;
  max-height: calc(100vh - 8px);
  overflow: auto;
  margin: auto;

  .pdf-wrapper {
    box-sizing: border-box;
    text-align: center;

    img {
      margin: auto;
    }

    .pdf-btm {
      padding-top: 20px;
      text-align: center;
    }

    .pdf-title {
      padding-bottom: 15px;
      text-align: center;
    }
  }
}

.main {
  margin-top: 17px;
  height: 100%;

  .pl24 {
    padding-left: 24px;
  }

  .mb24 {
    margin-bottom: 24px;
  }

  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 15px;
  }

  .search {
    ::v-deep .el-input {
      width: 230px;
      height: 32px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667db;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

::v-deep .el-button {
  // height: 35px !important;
}

::v-deep .el-input {
  height: 35px !important;
}

.back-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  // line-height: 32px;
}

.submit-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  //  line-height: 32px;
}

::v-deep .el-table__empty-text {
  margin-top: 10%;
}

.imgver {
  width: 60px;
  height: 55px;
  margin: 0 auto;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
