import echarts from "echarts";
export const option = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 40,
      end: 60,
      bottom: 10,
      fillerColor: "rgba(47,50,51,1)"
      // yAxisIndex: [0],
      // filterMode: "empty"
    },
    {
      type: "inside",
      realtime: true,
      start: 40,
      end: 60
    }
  ],
  tooltip: {
    show: true,
    trigger: "axis"
  },
  grid: {
    top: "30",
    left: "80",
    right: "80",
    bottom: "60"
  },
  xAxis: {
    type: "category",
    axisLabel: {
      color: "#fff",
      fontSize: 14,
      formatter: function (value) {
        return value.split(" ").join("\n");
      },
      showMinLabel: false,
      showMaxLabel: false
    },
    axisLine: {
      lineStyle: {
        color: "#9D9D9D",
        type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    data: []
  },
  yAxis: [
    {
      type: "value",

      nameTextStyle: {
        color: "#fff",
        fontSize: 14
      },
      axisLabel: {
        color: "#fff",
        fontSize: 14
      },
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        lineStyle: {
          color: "#9D9D9D",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      axisTick: {
        show: false
      }
    }
  ],
  series: [
    {
      name: "原始数据",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#6DB5C7"
      },
      lineStyle: {
        width: 1
      }
    },
    {
      name: "拟合结果",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#7CDD87"
      },
      lineStyle: {
        width: 1
      }
    }
    //   {
    //     name: "置信区间下限",
    //     data: [],
    //     type: "line",
    //     symbol: "none",
    //     itemStyle: {
    //       color: "#FF7575"
    //     },
    //     lineStyle: {
    //       width: 1
    //     }
    //   },
    //   {
    //     name: "置信区间上限",
    //     data: [],
    //     type: "line",
    //     symbol: "none",
    //     itemStyle: {
    //       color: "#FF7575"
    //     },
    //     lineStyle: {
    //       width: 1
    //     }
    //   }
  ]
};
var lineColor = "#27b5f0";
let areaStyle = {
  normal: {
    color: new echarts.graphic.LinearGradient(
      0,
      0,
      0,
      1,
      [
        {
          offset: 0,
          color: "rgba(247,181,0,0.50)"
        },
        {
          offset: 0.8,
          color: "rgba(247,181,0,0.00)"
        },
        {
          offset: 1,
          color: "rgba(247,181,0,0.00)"
        }
      ],
      false
    )
  }
};
export const option1 = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 0,
      end: 100,
      bottom: 10,
      textStyle: {
        color: "#58637f" //滚动条两边字体样式
      },
      fillerColor: "rgba(39, 114, 240, 0.2)",
      handleColor: "#194fdb", //滑块两端的线
      borderColor: "rgba(255,255,255,0.00)" //边框
      // yAxisIndex: [0],
      // filterMode: "empty"
    }
  ],
  tooltip: {
    show: true,
    trigger: "axis"
  },
  grid: {
    top: "10",
    left: "80",
    right: "80",
    bottom: "90"
  },
  xAxis: {
    type: "category",
    nameTextStyle: {
      // x轴name的样式调整
      color: "#58637f"
    },
    nameRotate: 270,
    axisLabel: {
      rotate: 10,
      color: "#cdd0d8",
      // interval: Math.ceil(time.length / 6),
      fontSize: 12,
      //   rotate: 4,
      showMinLabel: true,
      showMaxLabel: true,
      //   showMinLabel: false,
      formatter: function (value) {
        return value.split(" ").join("\n");
      }
    },
    axisLine: {
      lineStyle: {
        color: "#1c2b51"
        // type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    data: []
  },
  yAxis: [
    {
      type: "value",
      nameTextStyle: {
        color: "#58637f",
        fontSize: 14
      },
      axisLabel: {
        color: "#58637f",
        fontSize: 14
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        lineStyle: {
          color: "#1c2b51",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    }
    // {
    //   type: "value",
    //   axisLine: {
    //     lineStyle: {
    //       color: "#9D9D9D"
    //     }
    //   },
    //   axisTick: {
    //     show: false
    //   }
    // }
  ],
  series: [
    {
      name: "",
      data: [],
      type: "line",
      symbol: "none",
      areaStyle: areaStyle,

      itemStyle: {
        color: "#6DB5C7"
      },
      lineStyle: {
        color: lineColor,

        width: 2
      }
    }
    // {
    //   name: "拟合结果",
    //   data: [],
    //   type: "line",
    //   symbol: "none",
    //   itemStyle: {
    //     color: "#7CDD87"
    //   },
    //   lineStyle: {
    //     width: 1
    //   }
    // }
    //   {
    //     name: "置信区间下限",
    //     data: [],
    //     type: "line",
    //     symbol: "none",
    //     itemStyle: {
    //       color: "#FF7575"
    //     },
    //     lineStyle: {
    //       width: 1
    //     }
    //   },
    //   {
    //     name: "置信区间上限",
    //     data: [],
    //     type: "line",
    //     symbol: "none",
    //     itemStyle: {
    //       color: "#FF7575"
    //     },
    //     lineStyle: {
    //       width: 1
    //     }
    //   }
  ]
};
