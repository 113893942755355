<template>
  <div>
    <!-- <div id="loading_1" name="loading_1"  class=""></div> -->
    <meshForm @playChange="playChange" @inputPlay="inputPlay" @meshResultSelect="meshResultSelect" @getTargetOptions="getTargetOptions" @distortionChange="distortionChange" @inputEnter="inputEnter" @getTargetPosition="getTargetPosition" ref="meshformS" tem="tem"></meshForm>
    <!-- <el-button @click="moduleChange">点击切换{{ i }}</el-button> -->
    <!-- <div class="box mB10 win">
      
      <div class="fs18 noFlex" style="width: 86px">仿真场</div>
      <el-select v-model="valueNum" placeholder="请选择" @change="getValue">
        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div> -->
    <div class="tem_container_10_class box justify">
      <div class="tem_l radus24" v-show="meshTypeId == 3">
        <div v-if="true" class="tem_l_img">
          <img src="../../../../assets/images/sansheng/jiemian_png@2x.png" alt="" />
          <el-table v-if="tableData.length" :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%">
            <el-table-column align="center" prop="id" label="节点" :show-overflow-tooltip="true"> </el-table-column>

            <el-table-column align="center" prop="score" label="正应力(Mpa)" :show-overflow-tooltip="true"> </el-table-column>
          </el-table>
        </div>
        <div class="noDataMain health-empty" v-show="!tableData.length">
          <img src="../../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
      <div class="flex1 box flex-clumn">
        <div class="seismcl-echarts box flex-clumn pos-rel">
          <div class="down-load box justify">
            <!-- <span class="down-load-icon"> <img src="../../../../assets/images/daochu@2x.png" alt="" />导出 </span> -->
            <div></div>
            <div class="down-load-icon" @click="exportSeisData" v-if="isMeasure == '1' && (flag1 || flag2 || flag3) && seismicInfo.schemeId">导出</div>
            <!-- <div class="down-load-icon">导出</div> -->
          </div>
          <!-- <div class="flex1 box echarts-list">
            <div class="flex1 echarts-list-item box flex-clumn" v-if="flag1" style="width: 500px; border: 1px solid red">
              <div class="echarts-list-title">纵向测点{{ targetName1 }}</div>
              <div class="flex1" id="seismicId1"></div>
            </div>
            <div class="flex1 echarts-list-item box flex-clumn" v-if="flag2" style="width: 500px">
              <div class="echarts-list-title">横向测点{{ targetName2 }}</div>
              <div class="flex1" id="seismicId2"></div>
            </div>
            <div class="flex1 echarts-list-item box flex-clumn" v-if="flag3">
              <div class="echarts-list-title">竖向测点{{ targetName3 }}</div>
              <div class="flex1" id="seismicId3"></div>
            </div>

          </div> -->
          <!-- 图表开始 -->
          <div class="flex1 echarts-list" v-if="isMeasure == '1'">
            <!-- <div class="border echarts-list-item" :style="{ width: 2500 + 'px' }">
              <div class="echarts-list-title">纵向测点{{ targetName1 }}</div>
              <div class="item" id="seismicId1"></div>
            </div> -->
            <div class="border echarts-list-item" v-if="flag1" :style="{ width: seismicWith + '%' }">
              <!-- <div class="echarts-list-title">纵向测点{{ targetName1 }}</div> -->
              <div class="echarts-list-title">{{ targetName1 }}</div>
              <div class="item" id="seismicId1"></div>
            </div>
            <div class="border echarts-list-item" v-if="flag2" :style="{ width: seismicWith + '%' }">
              <!-- <div class="echarts-list-title">横向测点{{ targetName2 }}</div> -->
              <div class="echarts-list-title">{{ targetName2 }}</div>
              <div class="item" id="seismicId2"></div>
            </div>
            <div class="border echarts-list-item" v-if="flag3" :style="{ width: seismicWith + '%' }">
              <!-- <div class="echarts-list-title">竖向测点{{ targetName3 }}</div> -->
              <div class="echarts-list-title">{{ targetName3 }}</div>
              <div class="item" id="seismicId3"></div>
            </div>

            <div v-if="!flag1 && !flag2 && !flag3" class="noDataMain" style="padding-top: 60px">
              <img src="../../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>

          <div class="flex1 echarts-list" v-if="isMeasure == '2'">
            <div class="border echarts-list-item" v-if="flag4" :style="{ width: seismicWith1 + '%' }">
              <!-- <div class="echarts-list-title">纵向测点{{ targetName4 }}</div> -->
              <div class="echarts-list-title">{{ targetName4 }}</div>
              <div class="item" id="seismicId4"></div>
            </div>
            <div class="border echarts-list-item" v-if="flag5" :style="{ width: seismicWith1 + '%' }">
              <!-- <div class="echarts-list-title">横向测点{{ targetName5 }}</div> -->
              <div class="echarts-list-title">{{ targetName5 }}</div>
              <div class="item" id="seismicId5"></div>
            </div>
            <div class="border echarts-list-item" v-if="flag6" :style="{ width: seismicWith1 + '%' }">
              <!-- <div class="echarts-list-title">竖向测点{{ targetName6 }}</div> -->
              <div class="echarts-list-title">{{ targetName6 }}</div>
              <div class="item" id="seismicId6"></div>
            </div>

            <div v-if="!flag4 && !flag5 && !flag6" class="noDataMain" style="padding-top: 60px">
              <img src="../../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>

          <!-- 图表结束 -->
        </div>

        <div class="seismcl-canvas radus24 pos-rel flex1">
          <div id="container_s_10" style="top: 0px; left: 0px; width: 100%; height: 100%; position: absolute" v-show="calcFlag"></div>
          <canvas id="lut_s_10" width="250" height="450" style="top: 0px; left: 10px; width: 250px; height: 450px; position: absolute"></canvas>
          <div id="gui_s_10" style="top: 35px; right: 5px; position: absolute"></div>
          <div id="helper_s_10" style="bottom: 0; left: 195px; width: 128px; height: 128px; position: absolute"></div>
          <div class="info-res" v-show="nodeFlag != 3 && item.name">
            <span style="padding-right: 10px">{{ item.name }}</span>
            <span style="padding-right: 10px">{{ value }}</span>
            <span>{{ item.unit }}</span>
          </div>
          <draggable @start="onStart" @end="onEnd" @add="add2" animation="300"><div id="drag" class="reset-simulation-model" @click="resetIcon"></div></draggable>
        </div>
      </div>
      <!-- <div class="load-text" v-if="active == 1" :class="{ 'text-pos': meshTypeId == 3 }">
        <div>
          <p>重庆三圣特大桥为主跨（80+3×150+80）m 预应力混凝土连续刚构桥，主梁采用单箱单室截面，</p>
          <p>顶板宽 12.0m，底板宽 6.5m，顶板悬臂长度 2.75m；箱梁跨中梁高 3.3m，根部梁高 9.3m，梁底变化曲线为 1.7 次抛物线。</p>
        </div>
        <div style="margin-top: 8px">
          <p>主梁采用挂篮悬臂对称浇筑施工，</p>
          <p>梁段 1~6#长度为 3.0m，7~12#长度为 3.5m，13~19#长度为 4.0m</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { showMesh } from "../jsm/threemain/showmesh"; // ./jsm/threemain/showmesh   tomcloudmap
// import { Guidat, ViewHelper, LUT_text, CMThree, appShowMesh, CMMidas } from "tomcloudmap";

// import { appShowMesh } from "../../jsm2/three/appShowMesh";
// import { CMThree } from "../../jsm2/cm/cmthree";
// import { CMMidas } from "../../jsm2/cm/cmMidas";
// import { LUT_text } from "../../jsm2/LUT/LUT_text";
// import { ViewHelper } from "../../jsm2/helper/Viewport.ViewHelper";
// import { Guidat } from "../../jsm2/helper/guidathelper";
import { appShowMesh,CMThree,CMMidas,LUT_text,ViewHelper,Guidat} from "tomcloudmap";

import meshForm from "../common/meshForm";
import { typeOptionsW } from "../common/mesh";
import { scien } from "../scientifec";
import { option1 } from "./chart";
import echarts from "echarts";
import draggable from "vuedraggable";
export default {
  name: "meshshow",

  components: { meshForm, draggable },
  data() {
    return {
      loadingPage: null,
      drag: { start: 0, end: 0 },
      tableData: [],
      i: 1,
      valueNum: "U.U",
      // 'U.U', 'U.V', 'U.W', 'U.M', 'S.S11', 'S.S22', 'S.S33', 'S.S23', 'S.S13', 'S.S12'
      list: [],
      meshFlag: true,
      meshTypeId: "",
      active: "1",
      calcFlag: true,
      item: {},
      node: {},
      value: "",
      nodeFlag: "",
      chartList: {},
      seismicSize: "",
      seismicTime: {},
      isMeasure: "1",
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      flag5: false,
      flag6: false,
      targetName1: "",
      targetName2: "",
      targetName3: "",
      targetName4: "",
      targetName5: "",
      targetName6: "",
      seismicItem1: {},
      seismicItem2: {},
      seismicItem3: {},
      seismicItem4: {},
      seismicItem5: {},
      seismicItem6: {},
      seismicId1: null,
      seismicId2: null,
      seismicId3: null,
      seismicId4: null,
      seismicId5: null,
      seismicId6: null,
      seismicWith: 32, // 1==>98  2==>48.5   3===>32
      seismicWith1: 32, // 1==>98  2==>48.5   3===>32
      seismicInfo: {}
    };
  },
  computed: {},
  created() {
    // this.$bus.$on("caluResult", this.caluResult);
  },
  mounted() {
    this.active = this.$route.query.active || -1;
    console.log("active", this.active);
    document.getElementById("container_s_10").innerHTML = "";
    document.getElementById("helper_s_10").innerHTML = "";
    if (window.mainS) {
      window.mainS.clean();
      window.mainS = null;
    }
    this.$nextTick(() => {
      // this.midasVisible();
    });

    // this.moduleVisbileNew();
  },
  watch: {},
  methods: {
    initSeismic() {
      this.$refs["meshformS"].seismic = "seismic";
    },
    resetIcon() {
      console.log("click");
      window.mainS.setENVLast();
    },
    add2(e) {
      console.log("eeee-adddd", e);
    },
    onStart(e) {
      if (this.drag.start == 0 && this.drag.end == 0) {
        this.drag = { start: e.originalEvent.clientX, end: e.originalEvent.clientY };
      }
    },
    onEnd(e) {
      let start = e.originalEvent.clientX;
      let end = e.originalEvent.clientY;
      let oDiv = document.getElementById("drag");
      oDiv.style.right = this.drag.start - start + 20 + "px";
      oDiv.style.bottom = this.drag.end - end + 20  + "px";
    },
    // 导出节点数据
    exportSeisData() {
      let params = {
        ...this.seismicInfo
        //   size: this.seismicSize,
        //   ...this.seismicTime
      };
      // if (this.seismicItem1 && this.seismicItem1.targetId && this.flag1) {
      //   params.XId = this.seismicItem1.targetId;
      // }
      // if (this.seismicItem2 && this.seismicItem2.targetId && this.flag2) {
      //   params.YId = this.seismicItem2.targetId;
      // }
      // if (this.seismicItem3 && this.seismicItem3.targetId && this.flag3) {
      //   params.ZId = this.seismicItem3.targetId;
      // }
      console.log("params", params, "size", this.seismicSize);
      this.$axios
        .get(`${this.baseURL}simulation/export`, { responseType: "blob", params })
        .then((res) => {
          console.log("数据导出", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", "测点数据.xlsx");
          a.click();
        })
        .catch((err) => {});
    },

    // 实测数据-人工数据切换
    artificToMeasure(e) {
      //  e===>2  人工数据   else 1实测数据
      this.isMeasure = e;
      return;
      switch (e) {
        case "1":
          // if (this.seismicItem1 && this.seismicItem1.targetId && this.flag1) {
          //   params.XId = this.seismicItem1.targetId;
          // }
          // if (this.seismicItem2 && this.seismicItem2.targetId && this.flag2) {
          //   params.YId = this.seismicItem2.targetId;
          // }
          // if (this.seismicItem3 && this.seismicItem3.targetId && this.flag3) {
          //   params.ZId = this.seismicItem3.targetId;
          // }
          this.$nextTick(() => {
            this.disposeInitCharts();
          });

          break;
      }
    },

    playChange(play, frameFrequency) {
      if (play) {
        window.mainS.CM.play(frameFrequency);
      } else {
        let arr = window.mainS.CMs[0].getCurrentCMLevel();

        let len = arr[0].length;
        for (let i = 0; i < len; i++) {
          if (arr[0][i] == arr[1]) {
            // this.searchForm.currentCMLevel = i + 1;
            this.$refs.meshformS.currentCMLevel = i + 1;
            break;
          }
        }

        window.mainS.CM.stop();
      }
      window.mainS.CM.show();
    },

    inputPlay(frameFrequency) {
      window.mainS.CM.stop();

      window.mainS.CM.play(frameFrequency);
      window.mainS.CM.show();
    },

    // 仿真节点id
    getTargetPosition(item1) {
      console.log("反正节点", item1);
      this.node = item1;
      mainS.CMs[0].cleanInfoPoints();

      mainS.CMs[0].cleanSelectPoint();
      console.log("反正节点111111", item1);
      mainS.CMs[0].showInfoPoints([item1.name], 0x888888, 3);
      console.log("反正节点22221", item1);
      mainS.CMs[0].showInfoPoints([item1.name], 0x888888, 3);
      // let res = mainS.CMs[0].getStrOnePointValue(item1.name);
      let res = mainS.CMs[0].getOnePointAllCM(item1.name);

      console.log("获取仿真节点返回数据", res, this.item);
      let val = res[this.item.id][mainS.CMs[0].getCurrentCMLevel() - 1];
      this.value = scien(Number(val).toExponential());  
      return "地震荷载和其它不一样"; //   没有内力

      let reg = {};
      if (this.item.fid == 1 || this.item.fid == 3) {
        reg = info.STR;
      } else {
        reg = info.CM;
      }
      this.setNodeValue(item1, reg);

      return;

      // let val;
      // if (this.item.fid == 1) {
      //   if (Math.abs(res[this.item.text][0]) > Math.abs(res[this.item.text][1])) {
      //     val = res[this.item.text][0];
      //   } else {
      //     val = res[this.item.text][1];
      //   }
      // }
      // if (this.item.fid == 2) {
      //   let res1 = mainS.CMs[0].getOnePointAllCM(item1.name);
      //   console.log("获取仿真节点返回数据2222", res1);
      //   val = res1[this.item.id];
      // }
      // console.log("this.item", this.item, "val", val);
      // this.value = scien(Number(val).toExponential());
      // this.getStressDAta(item1.id, JSON.stringify(res));
    },
    setNodeValue(item1, res) {
      let val;
      if (this.item.fid == 1) {
        if (Math.abs(res[this.item.text][0]) > Math.abs(res[this.item.text][1])) {
          val = res[this.item.text][0];
        } else {
          val = res[this.item.text][1];
        }
      }
      if (this.item.fid == 2) {
        // res = mainW.CMs[0].getOnePointAllCM(this.item.id);
        val = res[this.item.id];
      }
      this.value = scien(Number(val).toExponential());
      this.getStressDAta(item1.id, JSON.stringify(res));
    },
    // 变形系数
    inputEnter(distortionFactor) {
      mainS.CMs[0].setTransRate(distortionFactor);
      mainS.CMs[0].show();
    },
    // 是否变形
    distortionChange(check, distortionFactor) {
      //  参数 1、是否变形 2、boolean 变形系数

      console.log("变形");
      mainS.CMs[0].setTransEnable(check);
      if (check) {
        mainS.CMs[0].setTransRate(distortionFactor);
      }

      mainS.CMs[0].show();
    },
    caluResult(res) {
      console.log("计算结果返回模型数据", res);

      document.getElementById("container_s_10").innerHTML = "";
      document.getElementById("helper_s_10").innerHTML = "";
      if (window.mainS) {
        window.mainS.clean();
        window.mainS = null;
      }
      // let odiv = document.getElementById("container_w_10");
      // console.log("odiv.childNodes(0)", odiv.childNodes);
      // odiv.removeChild(odiv.childNodes[0]);
      // this.calcFlag = false;
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.calcFlag = true;
      //     this.moduleVisbile(res.data.url); //获取zip包传入  参数  *************XXXXXXXXXXXXXXXX
      //   }, 1000);
      // });
      this.$nextTick(() => {
        // this.calcFlag = true;
        // this.moduleVisbile(/*"./cm/sanshengbridge.zip"*/ res.data.url); //获取zip包传入  参数  *************XXXXXXXXXXXXXXXX
        this.midasVisible(res.data.url);
        // this.moduleVisbileNew(res.data.url);
      });
    },
    getTargetOptions(id) {
      console.log("qiehuan555", id, mainS);
      this.meshTypeId = id; // 切换仿真类型，界面变化
      if (this.meshTypeId == 3) {
        window.mainS.three.viewpoint_px = 506;
      } else {
        window.mainS.three.viewpoint_px = 62;
      }
      // window.mainS.CMs[0].show();
    },
    getMeshItem(id) {
      let n = typeOptionsW.length;
      let arr = typeOptionsW;
      // while(true){
      //   let arr1=arr
      //   for(let i=0;i<arr1.length;i++){
      //     if(arr1[i].id===id){
      //       return arr1[i]
      //     }else{
      //       if(arr1[i].data && arr1[i].data.length){
      //         arr=arr1
      //       }
      //     }

      //   }
      // }
    },
    // 云图交互-仿真结果
    meshResultSelect(id, typeid) {
      console.log(typeid, 'typeid')
      if (this.meshFlag) return;

      // console.log("meshResultSelect-id", this.meshFlag, id, typeid, mainS.CMs[1], mainS.CMs[1].updateColors);
      this.nodeFlag = typeid;
      // let myid = typeOptions.filter((v) => v.id == id);
      if (typeid != 3) {
        let item = {};
        let n = typeOptionsW.length;
        for (let i = 0; i < n; i++) {
          if (typeOptionsW[i].id === typeid && typeOptionsW[i].data && typeOptionsW[i].data.length) {
            let k = typeOptionsW[i].data.length;
            for (let j = 0; j < k; j++) {
              console.log("云图交互", typeOptionsW[i].data[j].id);
              if (typeOptionsW[i].data[j].id === id) {
                this.item = typeOptionsW[i].data[j];
                this.item.fid = typeid;
                break;
              }
            }
          }
        }

        if (JSON.stringify(this.node) !== "{}") {
          this.getTargetPosition(this.node);
        }
      }

      setTimeout(() => {
        mainS.CMs[0].updateColors(id);

        window.mainS.CMs[0].show();
      }, 50);

      // switch (id) {
      //   case "2-1":
      //     mainS.CMs[0].updateColors("unoda0.u");
      //     break;
      //   case "2-2":
      //     mainS.CMs[0].updateColors("unoda0.v");
      //     break;
      //   case "2-3":
      //     mainS.CMs[0].updateColors("unoda0.w");
      //     break;
      //   case "2-4":
      //     mainS.CMs[0].updateColors("unoda1.anx");
      //     break;
      //   case "2-5":
      //     mainS.CMs[0].updateColors("unoda1.any");
      //     break;
      //   case "2-6":
      //     mainS.CMs[0].updateColors("unoda1.anz");
      //     break;
      // }
    },

    // 获取应力数据
    getStressDAta(pointsId, value) {
      let params = {
        pointsId, // 仿真节点id
        value // 应力
      };
      console.log("应力数据参数", params);
      // this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
      this.$axios.get(`${this.baseURL}simulation/calculate`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("获取应力5个点数据", res);

          let arr = [];
          if (res.data.length) {
            for (let i = 0; i < res.data.length; i++) {
              arr.push({ id: i + 1, score: res.data[i] });
            }
            this.tableData = arr;
          } else {
            this.tableData = [];
          }
        } else {
          // this.$message.error(res.errMsg);
          console.log("这里为什么报错呢", res);
        }
      });
    },

    getValue() {
      console.log("云图", this.valueNum);
      this.main.CM.show(this.valueNum);
      this.main.render();
    },
    getPointInfoAttr(id, info) {
      // mainT.CMs[0].cleanSelectPoint();
      mainS.CMs[0].cleanInfoPoints();
      this.$refs["meshformS"].formData.positionGroupId = "";
      this.$refs["meshformS"].formData.positionId = "";
      this.$refs["meshformS"].pavement = "";
      console.log("模型传出值id", id, "info", info);
      this.tableData = [];
    },
    async midasVisible(url = "") {
      console.log("云图加载.....url", url);

      this.loadingPage = this.$loading({
        lock: true,
        text: "云图正在解析中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      let that = this;
      let thisOfVUEentry = this;
      // clean DIV children ,on VDOM reload
      let cleanDIV = function (id) {
        let container = document.getElementById(id);
        container.innerHTML = "";
        // container.childNodes.forEach(function (item) {
        //     item.remove();
        // });
      };
      // clean webGL canvas  ,on VDOM reload
      let cleanWebGL = function (object) {
        object.clean();
        object = {};
      };
      // log router
      let logRouter = function (msg) {
        console.log("Log Router :", msg);
      };

      let meshCM;
      let output = function (x, y, id, info) {
        console.log(x, y, id, info);
      };
      ///////////////////////////////////////////////////////////////////////////////////
      // value of init webGL
      console.log("ddddddddddd22222222222222222222222222222222222222222222");
      let value = {
        DIV: "container_s_10",
        containerType: "div", //div or object
        ctl: "traceball", // orbit or traceball
        log: logRouter, //all log
        setting: {
          cameraAuto: false, // ture or false ,if the value is true or undefined ,below settings will be disable
          cameraPosition: [46,87,68], //[10, 0, 0],//x,y,z
          cameraMartix: false, //false or 4x4 martix ,if there has a array of matrix ,position will be igione
          cameraLookat: [], //x,y,z
          ctlTarget: [51, -13, -6], //x,y,z
          ////////////////////////////////////
          realTimeRender: true, //
          scale: 2,
          type: 'bridge'
        },

        fun: {
          // beforeInit,init,afterinit of webGL's
          afterInit: function (that) {},
          init: function (that) {}
          // onMouseClick: function (mainGL, raycaster, xy) {
          //     // console.log(raycaster, xy);
          //     if (mainGL.octree) {
          //         let index = mainGL.octree.pickup(
          //             raycaster.ray.origin,
          //             raycaster.ray.direction,
          //             raycaster
          //         );
          //         // mainGL.log("point id :", index);
          //         if (index)
          //             output(
          //                 xy.eventxy.x,
          //                 xy.eventxy.y,
          //                 index,
          //                 meshCM.getOnePointAllPressure(index)
          //             );
          //     }
          // },
          // onMouseMove: function (mainGL, raycaster, xy) {
          //     console.log(raycaster, xy);
          //     if (mainGL.octree) {
          //         let index = mainGL.octree.pickup(raycaster.ray.origin, raycaster.ray.direction, raycaster);
          //         mainGL.log("point id :", index);
          //     }
          // },
        },
        debug: true
      };

      // init webGL
      this.mainWebGL = new appShowMesh(value);
      ///////////////////////////////////////////////////////////////////////////////////
      //init LUT
      // must be init before CM init
      let inputValueLUT = {
        DIV: "lut_s_10",
        containerType: "div", //div or object
        kind: "curve", // curve ,default
        fontColor: { r: 1, g: 1.0, b: 1 },
        parent: this.mainWebGL
      };
      this.mainWebGL.LUT = new LUT_text(inputValueLUT);

      ///////////////////////////////////////////////////////////////////////////////////
      //init viewHelper
      let inputValueViewHelper = {
        DIV: "helper_s_10",
        containerType: "div", //div or object
        perpCamera: this.mainWebGL.perpCamera,
        parent: this.mainWebGL,
        up: "Y"
      };

      this.mainWebGL.viewHelper = new ViewHelper(inputValueViewHelper);

      /////////////////////////////////////////////////////////////////dizhen//////////////////dizhen.zip
      // value of CM mesh
      let meshMidas = {};
      let inputForCM = {
        parent: this.mainWebGL,
        // file: "./fire/TrialWYH.inp",
        // file: url || "./dizhen/dizhen.zip",
        file: url,
        check: 2,
        cache: false,
        rotate: {
          x: -90
        },
        callbacks: {
          log: logRouter,
          afterDraw: function (CM) {
            console.log("wwwwww", thisOfVUEentry, "ddddddddddd22222222222222222222222222222222222222222222");
            ///////////////////////////////////////////////////////////////////////////////////
            //demo : GUI init
            meshMidas.info.data.res = CM.info.data.res;
            meshMidas.info.data.str = CM.info.data.str;
            meshMidas.info.data.source = CM.info.data.source;
            let list = CM.getNameListOfCM().concat();

            // let inputValueGUI = {
            //   DIV: "gui_s_10",
            //   containerType: "div", //div or object
            //   CM: CM,
            //   parent: CM.parent,
            //   listCM: CM.getNameListOfCM()
            // };
            // CM.parent.gui = new Guidat(inputValueGUI);

            window.mainS.three.viewpoint_py = 708;
            window.mainS.three.viewpoint_px = 62;

            console.log("云图加载完成", thisOfVUEentry.$refs["meshformS"]);
            thisOfVUEentry.meshFlag = false;
            thisOfVUEentry.$nextTick(() => {
              console.log("云图加载完成=====", thisOfVUEentry, thisOfVUEentry.$refs["meshformS"]);
              if (window.mainS) {
                window.mainS.setENVLast();
              }

              thisOfVUEentry.$refs["meshformS"].getTargetOptions("2", false, window.mainS, "seismic");
              thisOfVUEentry.$refs["meshformS"].getSection();
              thisOfVUEentry.loadingPage.close();
            });
          }
        },

        center: true //true,false
      };
      //init Mesh
      meshCM = new CMThree(inputForCM);
      this.mainWebGL.CMs.push(meshCM);
      ///////////////////////////////////////////////////////////////////////////////////
      //huag up to global ENV,test only
      window.mainS = this.mainWebGL;
      let nodeMap = "./cm/node.map";
      let elementsMap = "./cm/elem.map";
      let nodeJSON_text = await this.getUrl(nodeMap);
      let elemJSON_text = await this.getUrl(elementsMap);
      let node = this.formatMap(nodeJSON_text);
      let elem = this.formatMap(elemJSON_text);
      // let elemJSON = {
      //   1: "1",
      //   2: "2",
      //   3: "3",
      //   4: "4",
      //   5: "5",
      //   6: "6",
      //   7: "7",
      //   8: "8",
      //   9: "9",
      //   10: "10",
      //   11: "11",
      //   12: "12",
      //   13: "13",
      //   14: "14",
      //   15: "15",
      //   16: "16",
      //   17: "17",
      //   18: "18",
      //   19: "19",
      //   20: "20",
      //   21: "21",
      //   22: "22",
      //   23: "23",
      //   24: "24",
      //   25: "25",
      //   26: "26",
      //   27: "27",
      //   28: "28",
      //   29: "29",
      //   30: "30",
      //   31: "31",
      //   32: "32",
      //   33: "33",
      //   34: "34",
      //   35: "35",
      //   36: "36",
      //   37: "37",
      //   38: "38",
      //   39: "39",
      //   40: "40",
      //   41: "41",
      //   42: "42",
      //   43: "43",
      //   44: "44",
      //   45: "45",
      //   46: "46",
      //   47: "47",
      //   48: "48",
      //   49: "49",
      //   50: "50",
      //   51: "51",
      //   52: "52",
      //   53: "53",
      //   54: "54",
      //   55: "55",
      //   56: "56",
      //   57: "57",
      //   58: "58",
      //   59: "59",
      //   60: "60",
      //   61: "61",
      //   62: "62",
      //   63: "63",
      //   64: "64",
      //   65: "65",
      //   66: "66",
      //   67: "67",
      //   68: "68",
      //   69: "69",
      //   70: "70",
      //   71: "71",
      //   72: "72",
      //   73: "73",
      //   74: "74",
      //   75: "75",
      //   76: "76",
      //   77: "77",
      //   78: "78",
      //   79: "79",
      //   80: "80",
      //   81: "81",
      //   82: "82",
      //   83: "83",
      //   84: "84",
      //   85: "85",
      //   86: "86",
      //   87: "87",
      //   88: "88",
      //   89: "89",
      //   90: "90",
      //   91: "91",
      //   92: "92",
      //   93: "93",
      //   94: "94",
      //   95: "95",
      //   96: "96",
      //   97: "97",
      //   98: "98",
      //   99: "99",
      //   100: "100",
      //   101: "101",
      //   102: "102",
      //   103: "103",
      //   104: "104",
      //   105: "105",
      //   106: "106",
      //   107: "107",
      //   108: "108",
      //   109: "109",
      //   110: "110",
      //   111: "111",
      //   112: "112",
      //   113: "113",
      //   114: "114",
      //   115: "115",
      //   116: "116",
      //   117: "117",
      //   118: "118",
      //   119: "119",
      //   120: "120",
      //   121: "121",
      //   122: "122",
      //   123: "123",
      //   124: "124",
      //   125: "125",
      //   126: "126",
      //   127: "127",
      //   128: "128",
      //   129: "129",
      //   130: "130",
      //   131: "131",
      //   132: "132",
      //   133: "133",
      //   134: "134",
      //   135: "135",
      //   136: "136",
      //   137: "137",
      //   138: "138",
      //   139: "139",
      //   140: "140",
      //   141: "141",
      //   142: "142",
      //   143: "143",
      //   144: "144",
      //   145: "145",
      //   146: "146",
      //   147: "147",
      //   148: "148",
      //   149: "149",
      //   150: "150",
      //   151: "151",
      //   152: "152",
      //   153: "153",
      //   154: "154",
      //   155: "155",
      //   156: "156",
      //   157: "157",
      //   158: "158",
      //   159: "159",
      //   160: "160",
      //   161: "161",
      //   162: "162",
      //   163: "163",
      //   164: "164",
      //   165: "165",
      //   166: "166",
      //   167: "167",
      //   168: "168",
      //   169: "169",
      //   170: "170",
      //   171: "171",
      //   172: "172",
      //   173: "173",
      //   174: "174",
      //   175: "175",
      //   176: "176",
      //   177: "177",
      //   178: "178",
      //   179: "179",
      //   180: "180",
      //   181: "181",
      //   182: "182",
      //   183: "183",
      //   184: "184",
      //   185: "185",
      //   186: "186",
      //   187: "187",
      //   188: "188",
      //   189: "189",
      //   190: "190",
      //   191: "191",
      //   192: "192",
      //   193: "193",
      //   194: "194",
      //   195: "195",
      //   196: "196",
      //   197: "197",
      //   198: "198",
      //   199: "199",
      //   200: "200",
      //   201: "201",
      //   202: "202",
      //   203: "203",
      //   204: "204",
      //   205: "205",
      //   206: "206",
      //   207: "207",
      //   208: "208",
      //   209: "209",
      //   210: "210",
      //   211: "211",
      //   212: "212",
      //   213: "213",
      //   214: "214",
      //   215: "215",
      //   216: "216",
      //   217: "217",
      //   218: "218",
      //   219: "219",
      //   220: "220",
      //   221: "221",
      //   222: "222",
      //   223: "223",
      //   224: "224",
      //   225: "225",
      //   226: "226",
      //   227: "227",
      //   228: "228",
      //   229: "229",
      //   230: "230",
      //   231: "231",
      //   232: "232",
      //   233: "233",
      //   234: "234",
      //   235: "235",
      //   236: "236",
      //   237: "237",
      //   238: "238",
      //   239: "239",
      //   240: "240",
      //   241: "241",
      //   242: "242",
      //   243: "243",
      //   244: "244",
      //   245: "245",
      //   246: "246",
      //   247: "247",
      //   248: "248",
      //   249: "249",
      //   250: "250",
      //   251: "251",
      //   252: "252",
      //   253: "253",
      //   254: "254",
      //   255: "255",
      //   256: "256",
      //   257: "257",
      //   258: "258",
      //   259: "259",
      //   260: "260",
      //   261: "261",
      //   262: "262",
      //   263: "263",
      //   264: "264",
      //   265: "265",
      //   266: "266",
      //   267: "267",
      //   268: "268",
      //   269: "269",
      //   270: "270",
      //   271: "271",
      //   272: "272",
      //   273: "273",
      //   274: "274",
      //   275: "275",
      //   276: "276",
      //   277: "277",
      //   278: "278",
      //   279: "279",
      //   280: "280",
      //   281: "281",
      //   282: "282",
      //   283: "283",
      //   284: "284",
      //   285: "285",
      //   286: "286",
      //   287: "287",
      //   288: "288",
      //   289: "289",
      //   290: "290",
      //   291: "291",
      //   292: "292",
      //   293: "293",
      //   294: "294",
      //   295: "295",
      //   296: "296",
      //   297: "297",
      //   298: "298",
      //   299: "299",
      //   300: "300",
      //   301: "301",
      //   302: "302",
      //   303: "303",
      //   304: "304",
      //   305: "305",
      //   306: "306",
      //   307: "307",
      //   308: "308",
      //   309: "309",
      //   310: "310",
      //   311: "311",
      //   312: "312",
      //   313: "313",
      //   314: "314",
      //   315: "315",
      //   316: "316",
      //   317: "317",
      //   318: "318",
      //   319: "319",
      //   320: "320",
      //   321: "321",
      //   322: "322",
      //   323: "323",
      //   324: "324",
      //   325: "325",
      //   326: "326",
      //   327: "327",
      //   328: "328",
      //   329: "329",
      //   330: "330",
      //   331: "331",
      //   332: "332",
      //   333: "333",
      //   334: "334",
      //   335: "335",
      //   336: "336",
      //   337: "337",
      //   338: "338",
      //   339: "339",
      //   340: "340",
      //   341: "341",
      //   342: "342",
      //   343: "343",
      //   344: "344",
      //   345: "345",
      //   346: "346",
      //   347: "347",
      //   348: "348",
      //   349: "349",
      //   350: "350",
      //   351: "351",
      //   352: "352",
      //   353: "353",
      //   354: "354",
      //   355: "355",
      //   356: "356",
      //   357: "357",
      //   358: "358",
      //   359: "359",
      //   360: "360",
      //   361: "361",
      //   362: "362",
      //   363: "363",
      //   364: "364",
      //   365: "365",
      //   366: "366",
      //   367: "367",
      //   368: "368",
      //   369: "369",
      //   370: "370",
      //   371: "371",
      //   372: "372",
      //   373: "373",
      //   374: "374",
      //   375: "375",
      //   376: "376",
      //   377: "377",
      //   378: "378",
      //   379: "379",
      //   380: "380",
      //   381: "381",
      //   382: "382",
      //   383: "383",
      //   384: "384",
      //   385: "385",
      //   386: "386",
      //   387: "387",
      //   388: "388",
      //   389: "389",
      //   390: "390",
      //   391: "391",
      //   392: "392",
      //   393: "393",
      //   394: "394",
      //   395: "395",
      //   396: "396",
      //   397: "397",
      //   398: "398",
      //   399: "399",
      //   400: "400",
      //   401: "401",
      //   402: "402",
      //   403: "403",
      //   404: "404",
      //   405: "405",
      //   406: "406",
      //   407: "407",
      //   408: "408",
      //   409: "409",
      //   410: "410",
      //   411: "411",
      //   412: "412",
      //   413: "413",
      //   414: "414",
      //   415: "415",
      //   416: "416",
      //   417: "417",
      //   418: "418",
      //   419: "419",
      //   420: "420",
      //   421: "421",
      //   422: "422",
      //   423: "423",
      //   424: "424",
      //   425: "425",
      //   426: "426",
      //   427: "427",
      //   428: "428",
      //   429: "429",
      //   430: "430",
      //   431: "431",
      //   432: "432",
      //   433: "433",
      //   434: "434",
      //   435: "435",
      //   436: "436",
      //   437: "437",
      //   438: "438",
      //   439: "439",
      //   440: "440",
      //   441: "441",
      //   442: "442",
      //   443: "443",
      //   444: "444",
      //   445: "445",
      //   446: "446",
      //   447: "451",
      //   448: "452",
      //   449: "453",
      //   450: "454",
      //   451: "455",
      //   452: "456",
      //   453: "457",
      //   454: "458",
      //   455: "459",
      //   456: "460",
      //   457: "461",
      //   458: "462",
      //   459: "463",
      //   460: "464",
      //   461: "465",
      //   462: "466",
      //   463: "467",
      //   464: "468",
      //   465: "469",
      //   466: "470",
      //   467: "471",
      //   468: "472",
      //   469: "473",
      //   470: "474",
      //   471: "475",
      //   472: "476",
      //   473: "477",
      //   474: "478",
      //   475: "479",
      //   476: "480",
      //   477: "481",
      //   478: "482",
      //   479: "483",
      //   480: "484",
      //   481: "485",
      //   482: "486",
      //   483: "487",
      //   484: "488",
      //   485: "489",
      //   486: "490",
      //   487: "491",
      //   488: "492",
      //   489: "493",
      //   490: "494",
      //   491: "495",
      //   492: "496",
      //   493: "497",
      //   494: "498",
      //   495: "499",
      //   496: "500",
      //   497: "501",
      //   498: "502",
      //   499: "503",
      //   500: "504",
      //   501: "505",
      //   502: "506",
      //   503: "507",
      //   504: "508",
      //   505: "509",
      //   506: "510",
      //   507: "511",
      //   508: "512",
      //   509: "513",
      //   510: "514",
      //   511: "515",
      //   512: "516",
      //   513: "517",
      //   514: "518",
      //   515: "519",
      //   516: "520",
      //   517: "521",
      //   518: "522",
      //   519: "523",
      //   520: "524",
      //   521: "525",
      //   522: "526",
      //   523: "527",
      //   524: "528",
      //   525: "529",
      //   526: "530",
      //   527: "531",
      //   528: "532",
      //   529: "533",
      //   530: "534",
      //   531: "535",
      //   532: "536",
      //   533: "537",
      //   534: "538",
      //   535: "539",
      //   536: "540",
      //   537: "541",
      //   538: "542",
      //   539: "543",
      //   540: "544",
      //   541: "545",
      //   542: "546",
      //   543: "547",
      //   544: "548",
      //   545: "549",
      //   546: "550",
      //   547: "551",
      //   548: "552",
      //   549: "553",
      //   550: "554",
      //   551: "555",
      //   552: "556",
      //   553: "557",
      //   554: "558",
      //   555: "559",
      //   556: "560",
      //   557: "561",
      //   558: "562",
      //   559: "563",
      //   560: "564",
      //   561: "565",
      //   562: "566",
      //   563: "567",
      //   564: "568",
      //   565: "569",
      //   566: "570",
      //   567: "571",
      //   568: "572",
      //   569: "573",
      //   570: "574",
      //   571: "575",
      //   572: "576",
      //   573: "577",
      //   574: "578",
      //   575: "579",
      //   576: "580",
      //   577: "581",
      //   578: "582",
      //   579: "583",
      //   580: "584",
      //   581: "585",
      //   582: "586",
      //   583: "587",
      //   584: "588",
      //   585: "589",
      //   586: "590",
      //   587: "591",
      //   588: "592",
      //   589: "593",
      //   590: "594",
      //   591: "595",
      //   592: "596",
      //   593: "597",
      //   594: "598",
      //   595: "599",
      //   596: "600",
      //   597: "601",
      //   598: "602",
      //   599: "603",
      //   600: "604",
      //   601: "605",
      //   602: "606",
      //   603: "607",
      //   604: "608",
      //   605: "609",
      //   606: "610",
      //   607: "611",
      //   608: "612",
      //   609: "613",
      //   610: "614",
      //   611: "615",
      //   612: "616",
      //   613: "617",
      //   614: "618",
      //   615: "619",
      //   616: "620",
      //   617: "621",
      //   618: "622",
      //   619: "623",
      //   620: "624",
      //   621: "625",
      //   622: "626",
      //   623: "627",
      //   624: "628",
      //   625: "629",
      //   626: "630",
      //   627: "631",
      //   628: "632",
      //   629: "633",
      //   630: "634",
      //   631: "635",
      //   632: "636",
      //   633: "637",
      //   634: "638",
      //   635: "639",
      //   636: "640",
      //   637: "641",
      //   638: "642",
      //   639: "643",
      //   640: "644",
      //   641: "645",
      //   642: "646",
      //   643: "647",
      //   644: "648",
      //   645: "649",
      //   646: "650",
      //   647: "651",
      //   648: "652",
      //   649: "653",
      //   650: "654",
      //   651: "655",
      //   652: "656",
      //   653: "657",
      //   654: "658",
      //   655: "659",
      //   656: "660",
      //   657: "661",
      //   658: "662",
      //   659: "663",
      //   660: "664",
      //   661: "665",
      //   662: "666",
      //   663: "667",
      //   664: "668",
      //   665: "669",
      //   666: "670",
      //   667: "671",
      //   668: "672",
      //   669: "673",
      //   670: "674",
      //   671: "675",
      //   672: "676",
      //   673: "677",
      //   674: "678",
      //   675: "679",
      //   676: "680",
      //   677: "681",
      //   678: "682",
      //   679: "683",
      //   680: "684",
      //   681: "685",
      //   682: "686",
      //   683: "687",
      //   684: "688",
      //   685: "689",
      //   686: "690",
      //   687: "691",
      //   688: "692",
      //   689: "693",
      //   690: "694",
      //   691: "695",
      //   692: "696",
      //   693: "697",
      //   694: "698",
      //   695: "699",
      //   696: "700",
      //   697: "701",
      //   698: "702",
      //   699: "703",
      //   700: "704",
      //   701: "705",
      //   702: "706",
      //   703: "707",
      //   704: "708",
      //   705: "709",
      //   706: "710",
      //   707: "711",
      //   708: "712",
      //   709: "713",
      //   710: "714",
      //   711: "715",
      //   712: "716",
      //   713: "717",
      //   714: "718",
      //   715: "719",
      //   716: "720",
      //   717: "721",
      //   718: "722",
      //   719: "723",
      //   720: "724",
      //   721: "725",
      //   722: "726",
      //   723: "727",
      //   724: "728",
      //   725: "729",
      //   726: "730",
      //   727: "731",
      //   728: "732",
      //   729: "733",
      //   730: "734",
      //   731: "735",
      //   732: "736",
      //   733: "737",
      //   734: "738",
      //   735: "739",
      //   736: "740",
      //   737: "741",
      //   738: "742",
      //   739: "743",
      //   740: "744",
      //   741: "745",
      //   742: "746",
      //   743: "747",
      //   744: "748",
      //   745: "749",
      //   746: "750",
      //   747: "751",
      //   748: "752",
      //   749: "753",
      //   750: "754",
      //   751: "755",
      //   752: "756",
      //   753: "757",
      //   754: "758",
      //   755: "759",
      //   756: "760",
      //   757: "761",
      //   758: "762",
      //   759: "763",
      //   760: "764",
      //   761: "765",
      //   762: "766",
      //   763: "767",
      //   764: "768",
      //   765: "769",
      //   766: "770",
      //   767: "771",
      //   768: "772",
      //   769: "773",
      //   770: "774",
      //   771: "775",
      //   772: "776",
      //   773: "777",
      //   774: "778",
      //   775: "779",
      //   776: "780",
      //   777: "781",
      //   778: "782",
      //   779: "783",
      //   780: "784",
      //   781: "785",
      //   782: "786",
      //   783: "787",
      //   784: "788",
      //   785: "789",
      //   786: "790",
      //   787: "791",
      //   788: "792",
      //   789: "793",
      //   790: "794",
      //   791: "795",
      //   792: "796",
      //   793: "797",
      //   794: "798",
      //   795: "799",
      //   796: "800",
      //   797: "801",
      //   798: "802",
      //   799: "803",
      //   800: "804",
      //   801: "805",
      //   802: "806",
      //   803: "807",
      //   804: "808",
      //   805: "809",
      //   806: "810",
      //   807: "811",
      //   808: "812",
      //   809: "813",
      //   810: "814",
      //   811: "815",
      //   812: "816",
      //   813: "817",
      //   814: "818",
      //   815: "819",
      //   816: "820",
      //   817: "821",
      //   818: "822",
      //   819: "823",
      //   820: "824",
      //   821: "825",
      //   822: "826",
      //   823: "827",
      //   824: "828",
      //   825: "829",
      //   826: "830",
      //   827: "831",
      //   828: "832",
      //   829: "833",
      //   830: "834",
      //   831: "835",
      //   832: "836",
      //   833: "837",
      //   834: "838",
      //   835: "839",
      //   836: "840",
      //   837: "841",
      //   838: "842",
      //   839: "843",
      //   840: "844",
      //   841: "845",
      //   842: "846",
      //   843: "847",
      //   844: "848",
      //   845: "849",
      //   846: "850",
      //   847: "851",
      //   848: "852",
      //   849: "853",
      //   850: "854",
      //   851: "855",
      //   852: "856",
      //   853: "857",
      //   854: "858",
      //   855: "859",
      //   856: "860",
      //   857: "861",
      //   858: "862",
      //   859: "863",
      //   860: "864",
      //   861: "865",
      //   862: "866",
      //   863: "867",
      //   864: "868",
      //   865: "869",
      //   866: "870",
      //   867: "871",
      //   868: "872",
      //   869: "873",
      //   870: "874",
      //   871: "875",
      //   872: "876",
      //   873: "877",
      //   874: "878",
      //   875: "879",
      //   876: "880",
      //   877: "881",
      //   878: "882",
      //   879: "883",
      //   880: "884",
      //   881: "885",
      //   882: "886",
      //   883: "887",
      //   884: "888",
      //   885: "889",
      //   886: "890",
      //   887: "891",
      //   888: "892",
      //   889: "893",
      //   890: "894",
      //   891: "895",
      //   892: "896",
      //   893: "897",
      //   894: "898",
      //   895: "899",
      //   896: "900",
      //   897: "901",
      //   898: "902",
      //   899: "903",
      //   900: "904",
      //   901: "905",
      //   902: "906",
      //   903: "907",
      //   904: "908",
      //   905: "909",
      //   906: "910",
      //   907: "911",
      //   908: "912",
      //   909: "913",
      //   910: "914",
      //   911: "915",
      //   912: "916",
      //   913: "917",
      //   914: "918",
      //   915: "919",
      //   916: "920",
      //   917: "921",
      //   918: "922",
      //   919: "923",
      //   920: "924",
      //   921: "925",
      //   922: "926",
      //   923: "927",
      //   924: "928",
      //   925: "929",
      //   926: "930",
      //   927: "931",
      //   928: "932",
      //   929: "933",
      //   930: "934",
      //   931: "935",
      //   932: "936",
      //   933: "937",
      //   934: "938",
      //   935: "939",
      //   936: "940",
      //   937: "941",
      //   938: "942",
      //   939: "943",
      //   940: "944",
      //   941: "945",
      //   942: "946",
      //   943: "947",
      //   944: "948",
      //   945: "949",
      //   946: "950",
      //   947: "951",
      //   948: "952",
      //   949: "953",
      //   950: "954",
      //   951: "955",
      //   952: "956",
      //   953: "957",
      //   954: "958",
      //   955: "959",
      //   956: "960",
      //   957: "961",
      //   958: "962",
      //   959: "963",
      //   960: "964",
      //   961: "965",
      //   962: "966",
      //   963: "967",
      //   964: "968",
      //   965: "969",
      //   966: "970",
      //   967: "971",
      //   968: "972",
      //   969: "973",
      //   970: "974",
      //   971: "975",
      //   972: "976",
      //   973: "977",
      //   974: "978",
      //   975: "979",
      //   976: "980",
      //   977: "981",
      //   978: "982",
      //   979: "983",
      //   980: "984",
      //   981: "985",
      //   982: "986",
      //   983: "987",
      //   984: "988",
      //   985: "989",
      //   986: "990",
      //   987: "991",
      //   988: "992",
      //   989: "993",
      //   990: "994",
      //   991: "995",
      //   992: "996",
      //   993: "997",
      //   994: "998",
      //   995: "999",
      //   996: "1000",
      //   997: "1001",
      //   998: "1002",
      //   999: "1003",
      //   1000: "1004",
      //   1001: "1005",
      //   1002: "1006",
      //   1003: "1007",
      //   1004: "1008",
      //   1005: "1009",
      //   1006: "1010",
      //   1007: "1011",
      //   1008: "1012",
      //   1009: "1013",
      //   1010: "1014",
      //   1011: "1015",
      //   1012: "1016",
      //   1013: "1017",
      //   1014: "1018",
      //   1015: "1019",
      //   1016: "1020",
      //   1017: "1021",
      //   1018: "1022",
      //   1019: "1023",
      //   1020: "1024",
      //   1021: "1025",
      //   1022: "1026",
      //   1023: "1027",
      //   1024: "1028",
      //   1025: "1029",
      //   1026: "1030",
      //   1027: "1031",
      //   1028: "1032",
      //   1029: "1033",
      //   1030: "1034",
      //   1031: "1035",
      //   1032: "1036",
      //   1033: "1037",
      //   1034: "1038",
      //   1035: "1039",
      //   1036: "1040",
      //   1037: "1041",
      //   1038: "1042",
      //   1039: "1043",
      //   1040: "1044",
      //   1041: "1045",
      //   1042: "1046",
      //   1043: "1047",
      //   1044: "1048",
      //   1045: "1049",
      //   1046: "1050",
      //   1047: "1051",
      //   1048: "1052",
      //   1049: "1053",
      //   1050: "1054",
      //   1051: "1055",
      //   1052: "1056",
      //   1053: "1057",
      //   1054: "1058",
      //   1055: "1059",
      //   1056: "1060",
      //   1057: "1061",
      //   1058: "1062",
      //   1059: "1063",
      //   1060: "1064",
      //   1061: "1065",
      //   1062: "1066",
      //   1063: "1067",
      //   1064: "1068",
      //   1065: "1069",
      //   1066: "1070",
      //   1067: "1071",
      //   1068: "1072",
      //   1069: "1073",
      //   1070: "1074",
      //   1071: "1075",
      //   1072: "1076",
      //   1073: "1077",
      //   1074: "1078",
      //   1075: "1079",
      //   1076: "1080",
      //   1077: "1081",
      //   1078: "1082",
      //   1079: "1083",
      //   1080: "1084",
      //   1081: "1085",
      //   1082: "1086",
      //   1083: "1087",
      //   1084: "1088",
      //   1085: "1089",
      //   1086: "1090",
      //   1087: "1091",
      //   1088: "1092",
      //   1089: "1093",
      //   1090: "1094",
      //   1091: "1095",
      //   1092: "1096",
      //   1093: "1097",
      //   1094: "1098",
      //   1095: "1099",
      //   1096: "1100",
      //   1097: "1101",
      //   1098: "1102",
      //   1099: "1103",
      //   1100: "1104",
      //   1101: "1105",
      //   1102: "1106",
      //   1103: "1107",
      //   1104: "1108",
      //   1105: "1109",
      //   1106: "1110",
      //   1107: "1111",
      //   1108: "1112",
      //   1109: "1113",
      //   1110: "1114",
      //   1111: "1115",
      //   1112: "1116",
      //   1113: "1117",
      //   1114: "1118",
      //   1115: "1119",
      //   1116: "1120",
      //   1117: "1121",
      //   1118: "1122",
      //   1119: "1123",
      //   1120: "1124",
      //   1121: "1125",
      //   1122: "1126",
      //   1123: "1127",
      //   1124: "1128",
      //   1125: "1129",
      //   1126: "1130",
      //   1127: "1131",
      //   1128: "1132"
      // };
      // let nodeJSON = {
      //   1: "1",
      //   2: "2",
      //   3: "3",
      //   4: "4",
      //   5: "5",
      //   6: "6",
      //   7: "7",
      //   8: "8",
      //   9: "9",
      //   10: "10",
      //   11: "11",
      //   12: "12",
      //   13: "13",
      //   14: "14",
      //   15: "15",
      //   16: "16",
      //   17: "17",
      //   18: "18",
      //   19: "19",
      //   20: "20",
      //   21: "21",
      //   22: "22",
      //   23: "23",
      //   24: "24",
      //   25: "25",
      //   26: "26",
      //   27: "27",
      //   28: "28",
      //   29: "29",
      //   30: "30",
      //   31: "31",
      //   32: "32",
      //   33: "33",
      //   34: "34",
      //   35: "35",
      //   36: "36",
      //   37: "37",
      //   38: "38",
      //   39: "39",
      //   40: "40",
      //   41: "41",
      //   42: "42",
      //   43: "43",
      //   44: "44",
      //   45: "45",
      //   46: "46",
      //   47: "47",
      //   48: "48",
      //   49: "49",
      //   50: "50",
      //   51: "51",
      //   52: "52",
      //   53: "53",
      //   54: "54",
      //   55: "55",
      //   56: "56",
      //   57: "57",
      //   58: "58",
      //   59: "59",
      //   60: "60",
      //   61: "61",
      //   62: "62",
      //   63: "63",
      //   64: "64",
      //   65: "65",
      //   66: "66",
      //   67: "67",
      //   68: "68",
      //   69: "69",
      //   70: "70",
      //   71: "71",
      //   72: "72",
      //   73: "73",
      //   74: "74",
      //   75: "75",
      //   76: "76",
      //   77: "77",
      //   78: "78",
      //   79: "79",
      //   80: "80",
      //   81: "81",
      //   82: "82",
      //   83: "83",
      //   84: "84",
      //   85: "85",
      //   86: "86",
      //   87: "87",
      //   88: "88",
      //   89: "89",
      //   90: "90",
      //   91: "91",
      //   92: "92",
      //   93: "93",
      //   94: "94",
      //   95: "95",
      //   96: "96",
      //   97: "97",
      //   98: "98",
      //   99: "99",
      //   100: "100",
      //   101: "101",
      //   102: "102",
      //   103: "103",
      //   104: "104",
      //   105: "105",
      //   106: "106",
      //   107: "107",
      //   108: "108",
      //   109: "109",
      //   110: "110",
      //   111: "111",
      //   112: "112",
      //   113: "113",
      //   114: "114",
      //   115: "115",
      //   116: "116",
      //   117: "117",
      //   118: "118",
      //   119: "119",
      //   120: "120",
      //   121: "121",
      //   122: "122",
      //   123: "123",
      //   124: "124",
      //   125: "125",
      //   126: "126",
      //   127: "127",
      //   128: "128",
      //   129: "129",
      //   130: "130",
      //   131: "131",
      //   132: "132",
      //   133: "133",
      //   134: "134",
      //   135: "135",
      //   136: "136",
      //   137: "137",
      //   138: "138",
      //   139: "139",
      //   140: "140",
      //   141: "141",
      //   142: "142",
      //   143: "143",
      //   144: "144",
      //   145: "145",
      //   146: "146",
      //   147: "147",
      //   148: "148",
      //   149: "149",
      //   150: "150",
      //   151: "151",
      //   152: "152",
      //   153: "153",
      //   154: "154",
      //   155: "155",
      //   156: "156",
      //   157: "157",
      //   158: "158",
      //   159: "159",
      //   160: "160",
      //   161: "161",
      //   162: "162",
      //   163: "163",
      //   164: "164",
      //   165: "165",
      //   166: "166",
      //   167: "167",
      //   168: "168",
      //   169: "169",
      //   170: "170",
      //   171: "171",
      //   172: "172",
      //   173: "173",
      //   174: "174",
      //   175: "175",
      //   176: "176",
      //   177: "177",
      //   178: "178",
      //   179: "179",
      //   180: "180",
      //   181: "181",
      //   182: "182",
      //   183: "183",
      //   184: "184",
      //   185: "185",
      //   186: "186",
      //   187: "187",
      //   188: "188",
      //   189: "189",
      //   190: "190",
      //   191: "191",
      //   192: "192",
      //   193: "193",
      //   194: "194",
      //   195: "195",
      //   196: "196",
      //   197: "197",
      //   198: "198",
      //   199: "200",
      //   200: "202",
      //   201: "204",
      //   202: "206",
      //   203: "208",
      //   204: "210",
      //   205: "214",
      //   206: "216",
      //   207: "217",
      //   208: "218",
      //   209: "219",
      //   210: "221",
      //   211: "222",
      //   212: "223",
      //   213: "224",
      //   214: "225",
      //   215: "226",
      //   216: "227",
      //   217: "228",
      //   218: "229",
      //   219: "230",
      //   220: "231",
      //   221: "232",
      //   222: "233",
      //   223: "234",
      //   224: "235",
      //   225: "236",
      //   226: "237",
      //   227: "238",
      //   228: "239",
      //   229: "240",
      //   230: "241",
      //   231: "242",
      //   232: "243",
      //   233: "244",
      //   234: "245",
      //   235: "246",
      //   236: "248",
      //   237: "249",
      //   238: "250",
      //   239: "251",
      //   240: "252",
      //   241: "253",
      //   242: "254",
      //   243: "255",
      //   244: "256",
      //   245: "257",
      //   246: "258",
      //   247: "259",
      //   248: "260",
      //   249: "261",
      //   250: "262",
      //   251: "263",
      //   252: "264",
      //   253: "265",
      //   254: "266",
      //   255: "267",
      //   256: "268",
      //   257: "269",
      //   258: "270",
      //   259: "271",
      //   260: "272",
      //   261: "273",
      //   262: "275",
      //   263: "276",
      //   264: "277",
      //   265: "278",
      //   266: "279",
      //   267: "280",
      //   268: "281",
      //   269: "282",
      //   270: "283",
      //   271: "284",
      //   272: "285",
      //   273: "286",
      //   274: "287",
      //   275: "288",
      //   276: "289",
      //   277: "290",
      //   278: "291",
      //   279: "292",
      //   280: "293",
      //   281: "294",
      //   282: "295",
      //   283: "296",
      //   284: "297",
      //   285: "298",
      //   286: "299",
      //   287: "300",
      //   288: "301",
      //   289: "302",
      //   290: "303",
      //   291: "304",
      //   292: "305",
      //   293: "306",
      //   294: "307",
      //   295: "308",
      //   296: "309",
      //   297: "310",
      //   298: "311",
      //   299: "312",
      //   300: "313",
      //   301: "314",
      //   302: "315",
      //   303: "316",
      //   304: "317",
      //   305: "318",
      //   306: "319",
      //   307: "320",
      //   308: "321",
      //   309: "322",
      //   310: "323",
      //   311: "324",
      //   312: "325",
      //   313: "326",
      //   314: "327",
      //   315: "328",
      //   316: "329",
      //   317: "330",
      //   318: "331",
      //   319: "332",
      //   320: "333",
      //   321: "334",
      //   322: "335",
      //   323: "336",
      //   324: "337",
      //   325: "338",
      //   326: "339",
      //   327: "340",
      //   328: "342",
      //   329: "343",
      //   330: "344",
      //   331: "345",
      //   332: "346",
      //   333: "347",
      //   334: "348",
      //   335: "349",
      //   336: "350",
      //   337: "351",
      //   338: "352",
      //   339: "353",
      //   340: "354",
      //   341: "355",
      //   342: "356",
      //   343: "357",
      //   344: "358",
      //   345: "359",
      //   346: "360",
      //   347: "361",
      //   348: "362",
      //   349: "363",
      //   350: "364",
      //   351: "365",
      //   352: "366",
      //   353: "367",
      //   354: "368",
      //   355: "369",
      //   356: "370",
      //   357: "371",
      //   358: "372",
      //   359: "373",
      //   360: "374",
      //   361: "375",
      //   362: "376",
      //   363: "377",
      //   364: "378",
      //   365: "379",
      //   366: "380",
      //   367: "381",
      //   368: "382",
      //   369: "383",
      //   370: "384",
      //   371: "385",
      //   372: "386",
      //   373: "387",
      //   374: "388",
      //   375: "389",
      //   376: "390",
      //   377: "391",
      //   378: "392",
      //   379: "393",
      //   380: "394",
      //   381: "395",
      //   382: "396",
      //   383: "397",
      //   384: "398",
      //   385: "399",
      //   386: "400",
      //   387: "401",
      //   388: "402",
      //   389: "403",
      //   390: "404",
      //   391: "405",
      //   392: "406",
      //   393: "407",
      //   394: "408",
      //   395: "409",
      //   396: "410",
      //   397: "411",
      //   398: "412",
      //   399: "413",
      //   400: "415",
      //   401: "416",
      //   402: "417",
      //   403: "418",
      //   404: "419",
      //   405: "420",
      //   406: "421",
      //   407: "422",
      //   408: "423",
      //   409: "424",
      //   410: "425",
      //   411: "426",
      //   412: "427",
      //   413: "428",
      //   414: "429",
      //   415: "430",
      //   416: "431",
      //   417: "432",
      //   418: "433",
      //   419: "434",
      //   420: "435",
      //   421: "436",
      //   422: "437",
      //   423: "438",
      //   424: "439",
      //   425: "440",
      //   426: "441",
      //   427: "442",
      //   428: "443",
      //   429: "444",
      //   430: "445",
      //   431: "446",
      //   432: "447",
      //   433: "448",
      //   434: "449",
      //   435: "450",
      //   436: "451",
      //   437: "452",
      //   438: "453",
      //   439: "454",
      //   440: "455",
      //   441: "456",
      //   442: "457",
      //   443: "458",
      //   444: "459",
      //   445: "460",
      //   446: "461",
      //   447: "462",
      //   448: "463",
      //   449: "464",
      //   450: "465",
      //   451: "466",
      //   452: "467",
      //   453: "468",
      //   454: "469",
      //   455: "470",
      //   456: "473",
      //   457: "474",
      //   458: "475",
      //   459: "476",
      //   460: "477",
      //   461: "478",
      //   462: "479",
      //   463: "480",
      //   464: "481",
      //   465: "482",
      //   466: "483",
      //   467: "484",
      //   468: "485",
      //   469: "486",
      //   470: "487",
      //   471: "488",
      //   472: "489",
      //   473: "490",
      //   474: "491",
      //   475: "492",
      //   476: "493",
      //   477: "494",
      //   478: "495",
      //   479: "496",
      //   480: "497",
      //   481: "498",
      //   482: "499",
      //   483: "500",
      //   484: "501",
      //   485: "502",
      //   486: "503",
      //   487: "504",
      //   488: "505",
      //   489: "506",
      //   490: "507",
      //   491: "508",
      //   492: "509",
      //   493: "510",
      //   494: "511",
      //   495: "512",
      //   496: "513",
      //   497: "514",
      //   498: "515",
      //   499: "516",
      //   500: "517",
      //   501: "518",
      //   502: "519",
      //   503: "520",
      //   504: "521",
      //   505: "522",
      //   506: "523",
      //   507: "524",
      //   508: "525",
      //   509: "526",
      //   510: "527",
      //   511: "528",
      //   512: "529",
      //   513: "530",
      //   514: "531",
      //   515: "532",
      //   516: "533",
      //   517: "534",
      //   518: "535",
      //   519: "536",
      //   520: "537",
      //   521: "538",
      //   522: "539",
      //   523: "540",
      //   524: "541",
      //   525: "542",
      //   526: "543",
      //   527: "544",
      //   528: "545",
      //   529: "546",
      //   530: "547",
      //   531: "548",
      //   532: "549",
      //   533: "550",
      //   534: "551",
      //   535: "552",
      //   536: "553",
      //   537: "554",
      //   538: "555",
      //   539: "556",
      //   540: "557",
      //   541: "558",
      //   542: "559",
      //   543: "560",
      //   544: "561",
      //   545: "562",
      //   546: "563",
      //   547: "564",
      //   548: "565",
      //   549: "566",
      //   550: "567",
      //   551: "568",
      //   552: "569",
      //   553: "570",
      //   554: "571",
      //   555: "572",
      //   556: "573",
      //   557: "574",
      //   558: "575",
      //   559: "576",
      //   560: "577",
      //   561: "578",
      //   562: "579",
      //   563: "580",
      //   564: "581",
      //   565: "582",
      //   566: "583",
      //   567: "584",
      //   568: "585",
      //   569: "586",
      //   570: "587",
      //   571: "588",
      //   572: "589",
      //   573: "590",
      //   574: "591",
      //   575: "592",
      //   576: "593",
      //   577: "594",
      //   578: "595",
      //   579: "596",
      //   580: "597",
      //   581: "598",
      //   582: "599",
      //   583: "600",
      //   584: "601",
      //   585: "602",
      //   586: "603",
      //   587: "604",
      //   588: "605",
      //   589: "606",
      //   590: "607",
      //   591: "608",
      //   592: "609",
      //   593: "610",
      //   594: "611",
      //   595: "612",
      //   596: "613",
      //   597: "614",
      //   598: "615",
      //   599: "616",
      //   600: "617",
      //   601: "618",
      //   602: "619",
      //   603: "620",
      //   604: "621",
      //   605: "622",
      //   606: "623",
      //   607: "624",
      //   608: "625",
      //   609: "626",
      //   610: "627",
      //   611: "628",
      //   612: "629",
      //   613: "630",
      //   614: "631",
      //   615: "632",
      //   616: "633",
      //   617: "634",
      //   618: "635",
      //   619: "636",
      //   620: "637",
      //   621: "638",
      //   622: "639",
      //   623: "640",
      //   624: "641",
      //   625: "642",
      //   626: "643",
      //   627: "644",
      //   628: "645",
      //   629: "646",
      //   630: "647",
      //   631: "648",
      //   632: "649",
      //   633: "650",
      //   634: "651",
      //   635: "652",
      //   636: "653",
      //   637: "654",
      //   638: "655",
      //   639: "656",
      //   640: "657",
      //   641: "658",
      //   642: "659",
      //   643: "660",
      //   644: "661",
      //   645: "662",
      //   646: "663",
      //   647: "664",
      //   648: "665",
      //   649: "666",
      //   650: "667",
      //   651: "668",
      //   652: "669",
      //   653: "670",
      //   654: "671",
      //   655: "672",
      //   656: "673",
      //   657: "674",
      //   658: "675",
      //   659: "676",
      //   660: "677",
      //   661: "678",
      //   662: "679",
      //   663: "680",
      //   664: "681",
      //   665: "682",
      //   666: "683",
      //   667: "684",
      //   668: "685",
      //   669: "686",
      //   670: "687",
      //   671: "688",
      //   672: "689",
      //   673: "690",
      //   674: "691",
      //   675: "692",
      //   676: "693",
      //   677: "694",
      //   678: "695",
      //   679: "696",
      //   680: "697",
      //   681: "698",
      //   682: "699",
      //   683: "700",
      //   684: "701",
      //   685: "702",
      //   686: "703",
      //   687: "704",
      //   688: "705",
      //   689: "706",
      //   690: "707",
      //   691: "708",
      //   692: "709",
      //   693: "710",
      //   694: "711",
      //   695: "712",
      //   696: "713",
      //   697: "714",
      //   698: "715",
      //   699: "716",
      //   700: "717",
      //   701: "718",
      //   702: "719",
      //   703: "720",
      //   704: "721",
      //   705: "722",
      //   706: "723",
      //   707: "724",
      //   708: "725",
      //   709: "726",
      //   710: "727",
      //   711: "728",
      //   712: "729",
      //   713: "730",
      //   714: "731",
      //   715: "732",
      //   716: "733",
      //   717: "734",
      //   718: "735",
      //   719: "736",
      //   720: "737",
      //   721: "738",
      //   722: "739",
      //   723: "740",
      //   724: "741",
      //   725: "742",
      //   726: "743",
      //   727: "744",
      //   728: "745",
      //   729: "746",
      //   730: "747",
      //   731: "748",
      //   732: "749",
      //   733: "750",
      //   734: "751",
      //   735: "752",
      //   736: "753",
      //   737: "754",
      //   738: "755",
      //   739: "756",
      //   740: "757",
      //   741: "758",
      //   742: "759",
      //   743: "760",
      //   744: "761",
      //   745: "762",
      //   746: "763",
      //   747: "764",
      //   748: "765",
      //   749: "766",
      //   750: "767",
      //   751: "768",
      //   752: "769",
      //   753: "770",
      //   754: "771",
      //   755: "772",
      //   756: "773",
      //   757: "774",
      //   758: "775",
      //   759: "776",
      //   760: "777",
      //   761: "778",
      //   762: "779",
      //   763: "780",
      //   764: "781",
      //   765: "782",
      //   766: "783",
      //   767: "784",
      //   768: "785",
      //   769: "786",
      //   770: "787",
      //   771: "788",
      //   772: "789",
      //   773: "790",
      //   774: "791",
      //   775: "792",
      //   776: "793",
      //   777: "794",
      //   778: "795",
      //   779: "796",
      //   780: "797",
      //   781: "798",
      //   782: "799",
      //   783: "800",
      //   784: "801",
      //   785: "802",
      //   786: "803",
      //   787: "804",
      //   788: "805",
      //   789: "806",
      //   790: "807",
      //   791: "808",
      //   792: "809",
      //   793: "810",
      //   794: "811",
      //   795: "812",
      //   796: "813",
      //   797: "814",
      //   798: "815",
      //   799: "816",
      //   800: "817",
      //   801: "818",
      //   802: "819",
      //   803: "820",
      //   804: "821",
      //   805: "822",
      //   806: "823",
      //   807: "824",
      //   808: "825",
      //   809: "826",
      //   810: "827",
      //   811: "828",
      //   812: "829",
      //   813: "830",
      //   814: "831",
      //   815: "832",
      //   816: "833",
      //   817: "834",
      //   818: "835",
      //   819: "836",
      //   820: "837",
      //   821: "838",
      //   822: "839",
      //   823: "840",
      //   824: "841",
      //   825: "842",
      //   826: "843",
      //   827: "844",
      //   828: "845",
      //   829: "846",
      //   830: "847",
      //   831: "848",
      //   832: "849",
      //   833: "850",
      //   834: "851",
      //   835: "852",
      //   836: "853",
      //   837: "854",
      //   838: "855",
      //   839: "856",
      //   840: "857",
      //   841: "858",
      //   842: "859",
      //   843: "860",
      //   844: "861",
      //   845: "862",
      //   846: "863",
      //   847: "864",
      //   848: "865",
      //   849: "866",
      //   850: "867",
      //   851: "868",
      //   852: "869",
      //   853: "870",
      //   854: "871",
      //   855: "872",
      //   856: "873",
      //   857: "874",
      //   858: "875",
      //   859: "876",
      //   860: "877",
      //   861: "878",
      //   862: "879",
      //   863: "880",
      //   864: "881",
      //   865: "882",
      //   866: "883",
      //   867: "884",
      //   868: "885",
      //   869: "886",
      //   870: "887",
      //   871: "888",
      //   872: "889",
      //   873: "890",
      //   874: "891",
      //   875: "892",
      //   876: "893",
      //   877: "894",
      //   878: "895",
      //   879: "896",
      //   880: "897",
      //   881: "898",
      //   882: "899",
      //   883: "900",
      //   884: "901",
      //   885: "902",
      //   886: "903",
      //   887: "904",
      //   888: "905",
      //   889: "906",
      //   890: "907",
      //   891: "908",
      //   892: "909",
      //   893: "910",
      //   894: "911",
      //   895: "912",
      //   896: "913",
      //   897: "914",
      //   898: "915",
      //   899: "916",
      //   900: "917",
      //   901: "918",
      //   902: "919",
      //   903: "920",
      //   904: "921",
      //   905: "922",
      //   906: "923",
      //   907: "924",
      //   908: "925",
      //   909: "926",
      //   910: "927",
      //   911: "928",
      //   912: "929",
      //   913: "930",
      //   914: "931",
      //   915: "932",
      //   916: "933",
      //   917: "934",
      //   918: "935",
      //   919: "936",
      //   920: "937",
      //   921: "938",
      //   922: "939",
      //   923: "940",
      //   924: "941",
      //   925: "942",
      //   926: "943",
      //   927: "944",
      //   928: "945",
      //   929: "946",
      //   930: "947",
      //   931: "948",
      //   932: "949",
      //   933: "950",
      //   934: "951",
      //   935: "952",
      //   936: "953",
      //   937: "954",
      //   938: "955",
      //   939: "956",
      //   940: "957",
      //   941: "958",
      //   942: "959",
      //   943: "960",
      //   944: "961",
      //   945: "962",
      //   946: "963",
      //   947: "964",
      //   948: "965",
      //   949: "966",
      //   950: "967",
      //   951: "968",
      //   952: "969",
      //   953: "970",
      //   954: "971",
      //   955: "972",
      //   956: "973",
      //   957: "974",
      //   958: "975",
      //   959: "976",
      //   960: "977",
      //   961: "978",
      //   962: "979",
      //   963: "980",
      //   964: "981",
      //   965: "982",
      //   966: "983",
      //   967: "984",
      //   968: "985",
      //   969: "986",
      //   970: "987",
      //   971: "988",
      //   972: "989",
      //   973: "990",
      //   974: "991",
      //   975: "992",
      //   976: "993",
      //   977: "994",
      //   978: "995",
      //   979: "996",
      //   980: "997",
      //   981: "998",
      //   982: "999",
      //   983: "1000",
      //   984: "1001",
      //   985: "1002",
      //   986: "1003",
      //   987: "1004",
      //   988: "1005",
      //   989: "1006",
      //   990: "1007",
      //   991: "1008",
      //   992: "1009",
      //   993: "1010",
      //   994: "1011",
      //   995: "1012",
      //   996: "1013",
      //   997: "1014",
      //   998: "1015",
      //   999: "1016",
      //   1000: "1017",
      //   1001: "1018",
      //   1002: "1019",
      //   1003: "1020",
      //   1004: "1021",
      //   1005: "1022",
      //   1006: "1023",
      //   1007: "1024",
      //   1008: "1025",
      //   1009: "1026",
      //   1010: "1027",
      //   1011: "1028",
      //   1012: "1029",
      //   1013: "1030",
      //   1014: "1031",
      //   1015: "1032",
      //   1016: "1033",
      //   1017: "1034",
      //   1018: "1035",
      //   1019: "1036",
      //   1020: "1037",
      //   1021: "1038",
      //   1022: "1039",
      //   1023: "1040",
      //   1024: "1041",
      //   1025: "1042",
      //   1026: "1043",
      //   1027: "1044",
      //   1028: "1045",
      //   1029: "1046",
      //   1030: "1047",
      //   1031: "1048",
      //   1032: "1049",
      //   1033: "1050",
      //   1034: "1051",
      //   1035: "1052",
      //   1036: "1053",
      //   1037: "1054",
      //   1038: "1055",
      //   1039: "1056",
      //   1040: "1057",
      //   1041: "1058",
      //   1042: "1059",
      //   1043: "1060",
      //   1044: "1061",
      //   1045: "1062",
      //   1046: "1063",
      //   1047: "1064",
      //   1048: "1065",
      //   1049: "1066",
      //   1050: "1067",
      //   1051: "1069",
      //   1052: "1070",
      //   1053: "1071",
      //   1054: "1072",
      //   1055: "1073",
      //   1056: "1074",
      //   1057: "1075",
      //   1058: "1076",
      //   1059: "1077",
      //   1060: "1078",
      //   1061: "1079",
      //   1062: "1080",
      //   1063: "1081",
      //   1064: "1082",
      //   1065: "1083",
      //   1066: "1084",
      //   1067: "1085",
      //   1068: "1086",
      //   1069: "1087",
      //   1070: "1088",
      //   1071: "1089",
      //   1072: "1090",
      //   1073: "1091",
      //   1074: "1092",
      //   1075: "1093",
      //   1076: "1094",
      //   1077: "1095",
      //   1078: "1096",
      //   1079: "1097",
      //   1080: "1098",
      //   1081: "1099",
      //   1082: "1100",
      //   1083: "1101",
      //   1084: "1102",
      //   1085: "1103",
      //   1086: "1104",
      //   1087: "1105",
      //   1088: "1106",
      //   1089: "1107",
      //   1090: "1108",
      //   1091: "1109",
      //   1092: "1110",
      //   1093: "1111",
      //   1094: "1112",
      //   1095: "1113",
      //   1096: "1114",
      //   1097: "1115",
      //   1098: "1116",
      //   1099: "1117",
      //   1100: "1118",
      //   1101: "1119",
      //   1102: "1120",
      //   1103: "1121",
      //   1104: "1122",
      //   1105: "1123",
      //   1106: "1124",
      //   1107: "1125",
      //   1108: "1126",
      //   1109: "1127",
      //   1110: "1128",
      //   1111: "1129",
      //   1112: "1130",
      //   1113: "1131",
      //   1114: "1132",
      //   1115: "1133",
      //   1116: "1134",
      //   1117: "1135",
      //   1118: "1136",
      //   1119: "1137",
      //   1120: "1138",
      //   1121: "1139",
      //   1122: "1140",
      //   1123: "1141",
      //   1124: "1142",
      //   1125: "1143",
      //   1126: "1144",
      //   1127: "1145",
      //   1128: "1146",
      //   1129: "1147",
      //   1130: "1148",
      //   1131: "1149",
      //   1132: "1150",
      //   1133: "1151",
      //   1134: "1152",
      //   1135: "1153",
      //   1136: "1154",
      //   1137: "1155",
      //   1138: "1156",
      //   1139: "1157",
      //   1140: "1158",
      //   1141: "1159",
      //   1142: "1160",
      //   1143: "1161",
      //   1144: "1162",
      //   1145: "1163",
      //   1146: "1164",
      //   1147: "1165",
      //   1148: "1166",
      //   1149: "1167",
      //   1150: "1168",
      //   1151: "1169",
      //   1152: "1170",
      //   1153: "1171",
      //   1154: "1172",
      //   1155: "1173",
      //   1156: "1174",
      //   1157: "1175",
      //   1158: "1176",
      //   1159: "1177",
      //   1160: "1178",
      //   1161: "1179",
      //   1162: "1180"
      // };
      // let nodeJSONOld = {
      //   1: "1",
      //   2: "2",
      //   3: "3",
      //   4: "4",
      //   5: "5",
      //   6: "6",
      //   7: "7",
      //   8: "8",
      //   9: "9",
      //   10: "10",
      //   11: "11",
      //   12: "12",
      //   13: "13",
      //   14: "14",
      //   15: "15",
      //   16: "16",
      //   17: "17",
      //   18: "18",
      //   19: "19",
      //   20: "20",
      //   21: "21",
      //   22: "22",
      //   23: "23",
      //   24: "24",
      //   25: "25",
      //   26: "26",
      //   27: "27",
      //   28: "28",
      //   29: "29",
      //   30: "30",
      //   31: "31",
      //   32: "32",
      //   33: "33",
      //   34: "34",
      //   35: "35",
      //   36: "36",
      //   37: "37",
      //   38: "38",
      //   39: "39",
      //   40: "40",
      //   41: "41",
      //   42: "42",
      //   43: "43",
      //   44: "44",
      //   45: "45",
      //   46: "46",
      //   47: "47",
      //   48: "48",
      //   49: "49",
      //   50: "50",
      //   51: "51",
      //   52: "52",
      //   53: "53",
      //   54: "54",
      //   55: "55",
      //   56: "56",
      //   57: "57",
      //   58: "58",
      //   59: "59",
      //   60: "60",
      //   61: "61",
      //   62: "62",
      //   63: "63",
      //   64: "64",
      //   65: "65",
      //   66: "66",
      //   67: "67",
      //   68: "68",
      //   69: "69",
      //   70: "70",
      //   71: "71",
      //   72: "72",
      //   73: "73",
      //   74: "74",
      //   75: "75",
      //   76: "76",
      //   77: "77",
      //   78: "78",
      //   79: "79",
      //   80: "80",
      //   81: "81",
      //   82: "82",
      //   83: "83",
      //   84: "84",
      //   85: "85",
      //   86: "86",
      //   87: "87",
      //   88: "88",
      //   89: "89",
      //   90: "90",
      //   91: "91",
      //   92: "92",
      //   93: "93",
      //   94: "94",
      //   95: "95",
      //   96: "96",
      //   97: "97",
      //   98: "98",
      //   99: "99",
      //   100: "100",
      //   101: "101",
      //   102: "102",
      //   103: "103",
      //   104: "104",
      //   105: "105",
      //   106: "106",
      //   107: "107",
      //   108: "108",
      //   109: "109",
      //   110: "110",
      //   111: "111",
      //   112: "112",
      //   113: "113",
      //   114: "114",
      //   115: "115",
      //   116: "116",
      //   117: "117",
      //   118: "118",
      //   119: "119",
      //   120: "120",
      //   121: "121",
      //   122: "122",
      //   123: "123",
      //   124: "124",
      //   125: "125",
      //   126: "126",
      //   127: "127",
      //   128: "128",
      //   129: "129",
      //   130: "130",
      //   131: "131",
      //   132: "132",
      //   133: "133",
      //   134: "134",
      //   135: "135",
      //   136: "136",
      //   137: "137",
      //   138: "138",
      //   139: "139",
      //   140: "140",
      //   141: "141",
      //   142: "142",
      //   143: "143",
      //   144: "144",
      //   145: "145",
      //   146: "146",
      //   147: "147",
      //   148: "148",
      //   149: "149",
      //   150: "150",
      //   151: "151",
      //   152: "152",
      //   153: "153",
      //   154: "154",
      //   155: "155",
      //   156: "156",
      //   157: "157",
      //   158: "158",
      //   159: "159",
      //   160: "160",
      //   161: "161",
      //   162: "162",
      //   163: "163",
      //   164: "164",
      //   165: "165",
      //   166: "166",
      //   167: "167",
      //   168: "168",
      //   169: "169",
      //   170: "170",
      //   171: "171",
      //   172: "172",
      //   173: "173",
      //   174: "174",
      //   175: "175",
      //   176: "176",
      //   177: "177",
      //   178: "178",
      //   179: "179",
      //   180: "180",
      //   181: "181",
      //   182: "182",
      //   183: "183",
      //   184: "184",
      //   185: "185",
      //   186: "186",
      //   187: "187",
      //   188: "188",
      //   189: "189",
      //   190: "190",
      //   191: "191",
      //   192: "192",
      //   193: "193",
      //   194: "194",
      //   195: "195",
      //   196: "196",
      //   197: "197",
      //   198: "198",
      //   200: "199",
      //   202: "200",
      //   204: "201",
      //   206: "202",
      //   208: "203",
      //   210: "204",
      //   214: "205",
      //   216: "206",
      //   217: "207",
      //   218: "208",
      //   219: "209",
      //   221: "210",
      //   222: "211",
      //   223: "212",
      //   224: "213",
      //   225: "214",
      //   226: "215",
      //   227: "216",
      //   228: "217",
      //   229: "218",
      //   230: "219",
      //   231: "220",
      //   232: "221",
      //   233: "222",
      //   234: "223",
      //   235: "224",
      //   236: "225",
      //   237: "226",
      //   238: "227",
      //   239: "228",
      //   240: "229",
      //   241: "230",
      //   242: "231",
      //   243: "232",
      //   244: "233",
      //   245: "234",
      //   246: "235",
      //   248: "236",
      //   249: "237",
      //   250: "238",
      //   251: "239",
      //   252: "240",
      //   253: "241",
      //   254: "242",
      //   255: "243",
      //   256: "244",
      //   257: "245",
      //   258: "246",
      //   259: "247",
      //   260: "248",
      //   261: "249",
      //   262: "250",
      //   263: "251",
      //   264: "252",
      //   265: "253",
      //   266: "254",
      //   267: "255",
      //   268: "256",
      //   269: "257",
      //   270: "258",
      //   271: "259",
      //   272: "260",
      //   273: "261",
      //   275: "262",
      //   276: "263",
      //   277: "264",
      //   278: "265",
      //   279: "266",
      //   280: "267",
      //   281: "268",
      //   282: "269",
      //   283: "270",
      //   284: "271",
      //   285: "272",
      //   286: "273",
      //   287: "274",
      //   288: "275",
      //   289: "276",
      //   290: "277",
      //   291: "278",
      //   292: "279",
      //   293: "280",
      //   294: "281",
      //   295: "282",
      //   296: "283",
      //   297: "284",
      //   298: "285",
      //   299: "286",
      //   300: "287",
      //   301: "288",
      //   302: "289",
      //   303: "290",
      //   304: "291",
      //   305: "292",
      //   306: "293",
      //   307: "294",
      //   308: "295",
      //   309: "296",
      //   310: "297",
      //   311: "298",
      //   312: "299",
      //   313: "300",
      //   314: "301",
      //   315: "302",
      //   316: "303",
      //   317: "304",
      //   318: "305",
      //   319: "306",
      //   320: "307",
      //   321: "308",
      //   322: "309",
      //   323: "310",
      //   324: "311",
      //   325: "312",
      //   326: "313",
      //   327: "314",
      //   328: "315",
      //   329: "316",
      //   330: "317",
      //   331: "318",
      //   332: "319",
      //   333: "320",
      //   334: "321",
      //   335: "322",
      //   336: "323",
      //   337: "324",
      //   338: "325",
      //   339: "326",
      //   340: "327",
      //   342: "328",
      //   343: "329",
      //   344: "330",
      //   345: "331",
      //   346: "332",
      //   347: "333",
      //   348: "334",
      //   349: "335",
      //   350: "336",
      //   351: "337",
      //   352: "338",
      //   353: "339",
      //   354: "340",
      //   355: "341",
      //   356: "342",
      //   357: "343",
      //   358: "344",
      //   359: "345",
      //   360: "346",
      //   361: "347",
      //   362: "348",
      //   363: "349",
      //   364: "350",
      //   365: "351",
      //   366: "352",
      //   367: "353",
      //   368: "354",
      //   369: "355",
      //   370: "356",
      //   371: "357",
      //   372: "358",
      //   373: "359",
      //   374: "360",
      //   375: "361",
      //   376: "362",
      //   377: "363",
      //   378: "364",
      //   379: "365",
      //   380: "366",
      //   381: "367",
      //   382: "368",
      //   383: "369",
      //   384: "370",
      //   385: "371",
      //   386: "372",
      //   387: "373",
      //   388: "374",
      //   389: "375",
      //   390: "376",
      //   391: "377",
      //   392: "378",
      //   393: "379",
      //   394: "380",
      //   395: "381",
      //   396: "382",
      //   397: "383",
      //   398: "384",
      //   399: "385",
      //   400: "386",
      //   401: "387",
      //   402: "388",
      //   403: "389",
      //   404: "390",
      //   405: "391",
      //   406: "392",
      //   407: "393",
      //   408: "394",
      //   409: "395",
      //   410: "396",
      //   411: "397",
      //   412: "398",
      //   413: "399",
      //   415: "400",
      //   416: "401",
      //   417: "402",
      //   418: "403",
      //   419: "404",
      //   420: "405",
      //   421: "406",
      //   422: "407",
      //   423: "408",
      //   424: "409",
      //   425: "410",
      //   426: "411",
      //   427: "412",
      //   428: "413",
      //   429: "414",
      //   430: "415",
      //   431: "416",
      //   432: "417",
      //   433: "418",
      //   434: "419",
      //   435: "420",
      //   436: "421",
      //   437: "422",
      //   438: "423",
      //   439: "424",
      //   440: "425",
      //   441: "426",
      //   442: "427",
      //   443: "428",
      //   444: "429",
      //   445: "430",
      //   446: "431",
      //   447: "432",
      //   448: "433",
      //   449: "434",
      //   450: "435",
      //   451: "436",
      //   452: "437",
      //   453: "438",
      //   454: "439",
      //   455: "440",
      //   456: "441",
      //   457: "442",
      //   458: "443",
      //   459: "444",
      //   460: "445",
      //   461: "446",
      //   462: "447",
      //   463: "448",
      //   464: "449",
      //   465: "450",
      //   466: "451",
      //   467: "452",
      //   468: "453",
      //   469: "454",
      //   470: "455",
      //   473: "456",
      //   474: "457",
      //   475: "458",
      //   476: "459",
      //   477: "460",
      //   478: "461",
      //   479: "462",
      //   480: "463",
      //   481: "464",
      //   482: "465",
      //   483: "466",
      //   484: "467",
      //   485: "468",
      //   486: "469",
      //   487: "470",
      //   488: "471",
      //   489: "472",
      //   490: "473",
      //   491: "474",
      //   492: "475",
      //   493: "476",
      //   494: "477",
      //   495: "478",
      //   496: "479",
      //   497: "480",
      //   498: "481",
      //   499: "482",
      //   500: "483",
      //   501: "484",
      //   502: "485",
      //   503: "486",
      //   504: "487",
      //   505: "488",
      //   506: "489",
      //   507: "490",
      //   508: "491",
      //   509: "492",
      //   510: "493",
      //   511: "494",
      //   512: "495",
      //   513: "496",
      //   514: "497",
      //   515: "498",
      //   516: "499",
      //   517: "500",
      //   518: "501",
      //   519: "502",
      //   520: "503",
      //   521: "504",
      //   522: "505",
      //   523: "506",
      //   524: "507",
      //   525: "508",
      //   526: "509",
      //   527: "510",
      //   528: "511",
      //   529: "512",
      //   530: "513",
      //   531: "514",
      //   532: "515",
      //   533: "516",
      //   534: "517",
      //   535: "518",
      //   536: "519",
      //   537: "520",
      //   538: "521",
      //   539: "522",
      //   540: "523",
      //   541: "524",
      //   542: "525",
      //   543: "526",
      //   544: "527",
      //   545: "528",
      //   546: "529",
      //   547: "530",
      //   548: "531",
      //   549: "532",
      //   550: "533",
      //   551: "534",
      //   552: "535",
      //   553: "536",
      //   554: "537",
      //   555: "538",
      //   556: "539",
      //   557: "540",
      //   558: "541",
      //   559: "542",
      //   560: "543",
      //   561: "544",
      //   562: "545",
      //   563: "546",
      //   564: "547",
      //   565: "548",
      //   566: "549",
      //   567: "550",
      //   568: "551",
      //   569: "552",
      //   570: "553",
      //   571: "554",
      //   572: "555",
      //   573: "556",
      //   574: "557",
      //   575: "558",
      //   576: "559",
      //   577: "560",
      //   578: "561",
      //   579: "562",
      //   580: "563",
      //   581: "564",
      //   582: "565",
      //   583: "566",
      //   584: "567",
      //   585: "568",
      //   586: "569",
      //   587: "570",
      //   588: "571",
      //   589: "572",
      //   590: "573",
      //   591: "574",
      //   592: "575",
      //   593: "576",
      //   594: "577",
      //   595: "578",
      //   596: "579",
      //   597: "580",
      //   598: "581",
      //   599: "582",
      //   600: "583",
      //   601: "584",
      //   602: "585",
      //   603: "586",
      //   604: "587",
      //   605: "588",
      //   606: "589",
      //   607: "590",
      //   608: "591",
      //   609: "592",
      //   610: "593",
      //   611: "594",
      //   612: "595",
      //   613: "596",
      //   614: "597",
      //   615: "598",
      //   616: "599",
      //   617: "600",
      //   618: "601",
      //   619: "602",
      //   620: "603",
      //   621: "604",
      //   622: "605",
      //   623: "606",
      //   624: "607",
      //   625: "608",
      //   626: "609",
      //   627: "610",
      //   628: "611",
      //   629: "612",
      //   630: "613",
      //   631: "614",
      //   632: "615",
      //   633: "616",
      //   634: "617",
      //   635: "618",
      //   636: "619",
      //   637: "620",
      //   638: "621",
      //   639: "622",
      //   640: "623",
      //   641: "624",
      //   642: "625",
      //   643: "626",
      //   644: "627",
      //   645: "628",
      //   646: "629",
      //   647: "630",
      //   648: "631",
      //   649: "632",
      //   650: "633",
      //   651: "634",
      //   652: "635",
      //   653: "636",
      //   654: "637",
      //   655: "638",
      //   656: "639",
      //   657: "640",
      //   658: "641",
      //   659: "642",
      //   660: "643",
      //   661: "644",
      //   662: "645",
      //   663: "646",
      //   664: "647",
      //   665: "648",
      //   666: "649",
      //   667: "650",
      //   668: "651",
      //   669: "652",
      //   670: "653",
      //   671: "654",
      //   672: "655",
      //   673: "656",
      //   674: "657",
      //   675: "658",
      //   676: "659",
      //   677: "660",
      //   678: "661",
      //   679: "662",
      //   680: "663",
      //   681: "664",
      //   682: "665",
      //   683: "666",
      //   684: "667",
      //   685: "668",
      //   686: "669",
      //   687: "670",
      //   688: "671",
      //   689: "672",
      //   690: "673",
      //   691: "674",
      //   692: "675",
      //   693: "676",
      //   694: "677",
      //   695: "678",
      //   696: "679",
      //   697: "680",
      //   698: "681",
      //   699: "682",
      //   700: "683",
      //   701: "684",
      //   702: "685",
      //   703: "686",
      //   704: "687",
      //   705: "688",
      //   706: "689",
      //   707: "690",
      //   708: "691",
      //   709: "692",
      //   710: "693",
      //   711: "694",
      //   712: "695",
      //   713: "696",
      //   714: "697",
      //   715: "698",
      //   716: "699",
      //   717: "700",
      //   718: "701",
      //   719: "702",
      //   720: "703",
      //   721: "704",
      //   722: "705",
      //   723: "706",
      //   724: "707",
      //   725: "708",
      //   726: "709",
      //   727: "710",
      //   728: "711",
      //   729: "712",
      //   730: "713",
      //   731: "714",
      //   732: "715",
      //   733: "716",
      //   734: "717",
      //   735: "718",
      //   736: "719",
      //   737: "720",
      //   738: "721",
      //   739: "722",
      //   740: "723",
      //   741: "724",
      //   742: "725",
      //   743: "726",
      //   744: "727",
      //   745: "728",
      //   746: "729",
      //   747: "730",
      //   748: "731",
      //   749: "732",
      //   750: "733",
      //   751: "734",
      //   752: "735",
      //   753: "736",
      //   754: "737",
      //   755: "738",
      //   756: "739",
      //   757: "740",
      //   758: "741",
      //   759: "742",
      //   760: "743",
      //   761: "744",
      //   762: "745",
      //   763: "746",
      //   764: "747",
      //   765: "748",
      //   766: "749",
      //   767: "750",
      //   768: "751",
      //   769: "752",
      //   770: "753",
      //   771: "754",
      //   772: "755",
      //   773: "756",
      //   774: "757",
      //   775: "758",
      //   776: "759",
      //   777: "760",
      //   778: "761",
      //   779: "762",
      //   780: "763",
      //   781: "764",
      //   782: "765",
      //   783: "766",
      //   784: "767",
      //   785: "768",
      //   786: "769",
      //   787: "770",
      //   788: "771",
      //   789: "772",
      //   790: "773",
      //   791: "774",
      //   792: "775",
      //   793: "776",
      //   794: "777",
      //   795: "778",
      //   796: "779",
      //   797: "780",
      //   798: "781",
      //   799: "782",
      //   800: "783",
      //   801: "784",
      //   802: "785",
      //   803: "786",
      //   804: "787",
      //   805: "788",
      //   806: "789",
      //   807: "790",
      //   808: "791",
      //   809: "792",
      //   810: "793",
      //   811: "794",
      //   812: "795",
      //   813: "796",
      //   814: "797",
      //   815: "798",
      //   816: "799",
      //   817: "800",
      //   818: "801",
      //   819: "802",
      //   820: "803",
      //   821: "804",
      //   822: "805",
      //   823: "806",
      //   824: "807",
      //   825: "808",
      //   826: "809",
      //   827: "810",
      //   828: "811",
      //   829: "812",
      //   830: "813",
      //   831: "814",
      //   832: "815",
      //   833: "816",
      //   834: "817",
      //   835: "818",
      //   836: "819",
      //   837: "820",
      //   838: "821",
      //   839: "822",
      //   840: "823",
      //   841: "824",
      //   842: "825",
      //   843: "826",
      //   844: "827",
      //   845: "828",
      //   846: "829",
      //   847: "830",
      //   848: "831",
      //   849: "832",
      //   850: "833",
      //   851: "834",
      //   852: "835",
      //   853: "836",
      //   854: "837",
      //   855: "838",
      //   856: "839",
      //   857: "840",
      //   858: "841",
      //   859: "842",
      //   860: "843",
      //   861: "844",
      //   862: "845",
      //   863: "846",
      //   864: "847",
      //   865: "848",
      //   866: "849",
      //   867: "850",
      //   868: "851",
      //   869: "852",
      //   870: "853",
      //   871: "854",
      //   872: "855",
      //   873: "856",
      //   874: "857",
      //   875: "858",
      //   876: "859",
      //   877: "860",
      //   878: "861",
      //   879: "862",
      //   880: "863",
      //   881: "864",
      //   882: "865",
      //   883: "866",
      //   884: "867",
      //   885: "868",
      //   886: "869",
      //   887: "870",
      //   888: "871",
      //   889: "872",
      //   890: "873",
      //   891: "874",
      //   892: "875",
      //   893: "876",
      //   894: "877",
      //   895: "878",
      //   896: "879",
      //   897: "880",
      //   898: "881",
      //   899: "882",
      //   900: "883",
      //   901: "884",
      //   902: "885",
      //   903: "886",
      //   904: "887",
      //   905: "888",
      //   906: "889",
      //   907: "890",
      //   908: "891",
      //   909: "892",
      //   910: "893",
      //   911: "894",
      //   912: "895",
      //   913: "896",
      //   914: "897",
      //   915: "898",
      //   916: "899",
      //   917: "900",
      //   918: "901",
      //   919: "902",
      //   920: "903",
      //   921: "904",
      //   922: "905",
      //   923: "906",
      //   924: "907",
      //   925: "908",
      //   926: "909",
      //   927: "910",
      //   928: "911",
      //   929: "912",
      //   930: "913",
      //   931: "914",
      //   932: "915",
      //   933: "916",
      //   934: "917",
      //   935: "918",
      //   936: "919",
      //   937: "920",
      //   938: "921",
      //   939: "922",
      //   940: "923",
      //   941: "924",
      //   942: "925",
      //   943: "926",
      //   944: "927",
      //   945: "928",
      //   946: "929",
      //   947: "930",
      //   948: "931",
      //   949: "932",
      //   950: "933",
      //   951: "934",
      //   952: "935",
      //   953: "936",
      //   954: "937",
      //   955: "938",
      //   956: "939",
      //   957: "940",
      //   958: "941",
      //   959: "942",
      //   960: "943",
      //   961: "944",
      //   962: "945",
      //   963: "946",
      //   964: "947",
      //   965: "948",
      //   966: "949",
      //   967: "950",
      //   968: "951",
      //   969: "952",
      //   970: "953",
      //   971: "954",
      //   972: "955",
      //   973: "956",
      //   974: "957",
      //   975: "958",
      //   976: "959",
      //   977: "960",
      //   978: "961",
      //   979: "962",
      //   980: "963",
      //   981: "964",
      //   982: "965",
      //   983: "966",
      //   984: "967",
      //   985: "968",
      //   986: "969",
      //   987: "970",
      //   988: "971",
      //   989: "972",
      //   990: "973",
      //   991: "974",
      //   992: "975",
      //   993: "976",
      //   994: "977",
      //   995: "978",
      //   996: "979",
      //   997: "980",
      //   998: "981",
      //   999: "982",
      //   1000: "983",
      //   1001: "984",
      //   1002: "985",
      //   1003: "986",
      //   1004: "987",
      //   1005: "988",
      //   1006: "989",
      //   1007: "990",
      //   1008: "991",
      //   1009: "992",
      //   1010: "993",
      //   1011: "994",
      //   1012: "995",
      //   1013: "996",
      //   1014: "997",
      //   1015: "998",
      //   1016: "999",
      //   1017: "1000",
      //   1018: "1001",
      //   1019: "1002",
      //   1020: "1003",
      //   1021: "1004",
      //   1022: "1005",
      //   1023: "1006",
      //   1024: "1007",
      //   1025: "1008",
      //   1026: "1009",
      //   1027: "1010",
      //   1028: "1011",
      //   1029: "1012",
      //   1030: "1013",
      //   1031: "1014",
      //   1032: "1015",
      //   1033: "1016",
      //   1034: "1017",
      //   1035: "1018",
      //   1036: "1019",
      //   1037: "1020",
      //   1038: "1021",
      //   1039: "1022",
      //   1040: "1023",
      //   1041: "1024",
      //   1042: "1025",
      //   1043: "1026",
      //   1044: "1027",
      //   1045: "1028",
      //   1046: "1029",
      //   1047: "1030",
      //   1048: "1031",
      //   1049: "1032",
      //   1050: "1033",
      //   1051: "1034",
      //   1052: "1035",
      //   1053: "1036",
      //   1054: "1037",
      //   1055: "1038",
      //   1056: "1039",
      //   1057: "1040",
      //   1058: "1041",
      //   1059: "1042",
      //   1060: "1043",
      //   1061: "1044",
      //   1062: "1045",
      //   1063: "1046",
      //   1064: "1047",
      //   1065: "1048",
      //   1066: "1049",
      //   1067: "1050",
      //   1069: "1051",
      //   1070: "1052",
      //   1071: "1053",
      //   1072: "1054",
      //   1073: "1055",
      //   1074: "1056",
      //   1075: "1057",
      //   1076: "1058",
      //   1077: "1059",
      //   1078: "1060",
      //   1079: "1061",
      //   1080: "1062",
      //   1081: "1063",
      //   1082: "1064",
      //   1083: "1065",
      //   1084: "1066",
      //   1085: "1067",
      //   1086: "1068",
      //   1087: "1069",
      //   1088: "1070",
      //   1089: "1071",
      //   1090: "1072",
      //   1091: "1073",
      //   1092: "1074",
      //   1093: "1075",
      //   1094: "1076",
      //   1095: "1077",
      //   1096: "1078",
      //   1097: "1079",
      //   1098: "1080",
      //   1099: "1081",
      //   1100: "1082",
      //   1101: "1083",
      //   1102: "1084",
      //   1103: "1085",
      //   1104: "1086",
      //   1105: "1087",
      //   1106: "1088",
      //   1107: "1089",
      //   1108: "1090",
      //   1109: "1091",
      //   1110: "1092",
      //   1111: "1093",
      //   1112: "1094",
      //   1113: "1095",
      //   1114: "1096",
      //   1115: "1097",
      //   1116: "1098",
      //   1117: "1099",
      //   1118: "1100",
      //   1119: "1101",
      //   1120: "1102",
      //   1121: "1103",
      //   1122: "1104",
      //   1123: "1105",
      //   1124: "1106",
      //   1125: "1107",
      //   1126: "1108",
      //   1127: "1109",
      //   1128: "1110",
      //   1129: "1111",
      //   1130: "1112",
      //   1131: "1113",
      //   1132: "1114",
      //   1133: "1115",
      //   1134: "1116",
      //   1135: "1117",
      //   1136: "1118",
      //   1137: "1119",
      //   1138: "1120",
      //   1139: "1121",
      //   1140: "1122",
      //   1141: "1123",
      //   1142: "1124",
      //   1143: "1125",
      //   1144: "1126",
      //   1145: "1127",
      //   1146: "1128",
      //   1147: "1129",
      //   1148: "1130",
      //   1149: "1131",
      //   1150: "1132",
      //   1151: "1133",
      //   1152: "1134",
      //   1153: "1135",
      //   1154: "1136",
      //   1155: "1137",
      //   1156: "1138",
      //   1157: "1139",
      //   1158: "1140",
      //   1159: "1141",
      //   1160: "1142",
      //   1161: "1143",
      //   1162: "1144",
      //   1163: "1145",
      //   1164: "1146",
      //   1165: "1147",
      //   1166: "1148",
      //   1167: "1149",
      //   1168: "1150",
      //   1169: "1151",
      //   1170: "1152",
      //   1171: "1153",
      //   1172: "1154",
      //   1173: "1155",
      //   1174: "1156",
      //   1175: "1157",
      //   1176: "1158",
      //   1177: "1159",
      //   1178: "1160",
      //   1179: "1161",
      //   1180: "1162"
      // };
      let elemJSON = elem.outJson;
      let nodeJSON = node.outJson;
      let nodeJSONOld = node.outOldJson;
      let inputForMidas = {
        parent: this.mainWebGL,
        file: "./cm/xmh.mct",
        check: 2,
        visible: false,
        extraSetting: {
          transDir: 1 //3=u,v,w,1=only one ,default = 3
        },
        extra: {
          midas: {
            fun: {
              replaceNode: function (data) {
                console.log(data);
                let oldData = JSON.parse(JSON.stringify(data.nodes));
                // console.log(oldData);
                data.nodes = [];
                for (let i in nodeJSON) {
                  data.nodes[i] = JSON.parse(JSON.stringify(oldData[nodeJSON[i]]));
                  // console.log(i);
                }
              },
              replaceElement: function (data) {
                // console.log(data);
                let oldData = JSON.parse(JSON.stringify(data.elements));
                // console.log(oldData);
                data.elements = [];
                for (let i in elemJSON) {
                  if (typeof oldData[elemJSON[i]] != "undefined" && oldData[elemJSON[i]]) {
                    data.elements[i] = JSON.parse(JSON.stringify(oldData[elemJSON[i]]));
                    // console.log(i);
                    if (i == 451) {
                      let agc = 1;
                    }
                    for (let j in data.elements[i].iNs) {
                      data.elements[i].iNs[j] = nodeJSONOld[data.elements[i].iNs[j]];
                    }
                  }
                  // JSON.parse(JSON.stringify(oldData[elemJSON[i]]));
                }
              }
            },
            //     data: {
            //         res: meshCM.info.data.res,
            //         str: meshCM.info.data.str,
            //     },
            resOBJ: meshCM
          }
        },
        // rotate:{x:-90},//{x:0,y:90,z:0}
        callbacks: {
          log: logRouter,
          afterDraw: function (CM) {
            // CM.info.data.res = meshCM.info.data.res;
            // CM.info.data.str = meshCM.info.data.str;
          }
        }
      };
      //init midas
      meshMidas = new CMMidas(inputForMidas);
      this.mainWebGL.CMs.push(meshMidas);
      ///////////////////////////////////////////////////////////////////////////////////
      //huag up to global ENV,test only

      window.mainS = this.mainWebGL;
    },
    async getUrl(url) {
        let xml = new XMLHttpRequest();
        xml.open("GET", url);
        xml.send();
        let text = await new Promise((resolve, reject) => {
            xml.onload = () => {
                console.log("OK", xml);
                if (xml.status == 200) {
                    resolve(xml.response);
                } else if (xml.status == 404) {
                    reject(new Error(xml.status));
                } else {
                    reject(new Error("error"));
                }
            };
        });
        return text;
    },
    formatMap(text) {
        let outJson = {};
        let outOldJson = {};

        let lines = text.split("\n");
        for (let perLine of lines) {
            if (perLine != "") {
                let perArray = perLine.split(/\s+/g);
                outJson[perArray[1]] = perArray[0];
                outOldJson[perArray[0]] = perArray[1];
            }
        }
        return { outJson, outOldJson };
    },

    // 父类调用   实测数据
    initEcharts(xData, yData, myChart, name, flag, size, time, targetId) {
      this.seismicInfo = { ...time, size, ...targetId };
      console.log("地震荷载传图表值", "xdata", xData, "ydata", yData, "mychart", myChart, "name", name, "flag", flag);
      let id = "";
      if (!name) {
        switch (flag) {
          case "1":
            this.flag1 = false;
            this.seismicItem1 = {};
            break;
          case "2":
            this.flag2 = false;
            this.seismicItem2 = {};
            break;
          case "3":
            this.flag3 = false;
            this.seismicItem3 = {};
            break;
          case "4":
            this.flag4 = false;
            this.seismicItem4 = {};
            break;
          case "5":
            this.flag5 = false;
            this.seismicItem5 = {};
            break;
          case "6":
            this.flag6 = false;
            this.seismicItem6 = {};
            break;
        }
        let m = 0,
          n = 0;
        if (this.flag1) {
          m++;
        }
        if (this.flag2) {
          m++;
        }
        if (this.flag3) {
          m++;
        }

        if (this.flag4) {
          n++;
        }
        if (this.flag5) {
          n++;
        }
        if (this.flag6) {
          n++;
        }

        if (n == 0) {
          this.seismicWith1 = 0;
        }
        if (n == 1) {
          this.seismicWith1 = 98;
        }
        if (n == 2) {
          this.seismicWith1 = 48.5;
        }
        if (n == 3) {
          this.seismicWith1 = 32;
        }
        if (n > 3 || n <= 0) {
          this.seismicWith1 = 0;
        }

        if (m == 0) {
          this.seismicWith = 0;
        }
        if (m == 1) {
          this.seismicWith = 98;
        }
        if (m == 2) {
          this.seismicWith = 48.5;
        }
        if (m == 3) {
          this.seismicWith = 32;
        }
        if (m > 3 || m <= 0) {
          this.seismicWith = 0;
        }
        this.$nextTick(() => {
          this.disposeInitCharts();
        });

        return;
      } else {
        if (this.isMeasure == "1") {
          this.seismicSize = size;
          this.seismicTime = time;
        }

        //   1==>98  2==>48.5   3===>32
        let k = 0,
          p = 0;
        if ((flag == "1" && !this.flag1) || this.flag1) {
          k++;
        }
        if ((!this.flag2 && flag == "2") || this.flag2) {
          k++;
        }
        if ((!this.flag3 && flag == "3") || this.flag3) {
          k++;
        }
        if (k == 0) {
          this.seismicWith = 0;
        }
        if (k == 1) {
          this.seismicWith = 98;
        }
        if (k == 2) {
          this.seismicWith = 48.5;
        }
        if (k == 3) {
          this.seismicWith = 32;
        }
        if (k > 3 || k <= 0) {
          this.seismicWith = 0;
        }
        // console.log("图表k", k, "seismicWith", this.seismicWith);
        if ((flag == "4" && !this.flag4) || this.flag4) {
          p++;
        }
        if ((!this.flag5 && flag == "5") || this.flag5) {
          p++;
        }
        if ((!this.flag6 && flag == "6") || this.flag6) {
          p++;
        }
        if (p == 0) {
          this.seismicWith1 = 0;
        }
        if (p == 1) {
          this.seismicWith1 = 98;
        }
        if (p == 2) {
          this.seismicWith1 = 48.5;
        }
        if (p == 3) {
          this.seismicWith1 = 32;
        }
        if (p > 3 || p <= 0) {
          this.seismicWith1 = 0;
        }
        switch (flag) {
          case "1":
            this.flag1 = true;
            this.targetName1 = name;
            id = "seismicId1";
            this.seismicItem1 = {
              id,
              xData,
              yData,
              myChart,
              name,
              flag,
              targetId
            };
            // this.$nextTick(() => {
            //   console.log("获取dom", document.getElementById(id), this.seismicId1);
            //   this.asyncInitCharts(id, xData, yData, this.seismicId1, name);
            // });
            break;
          case "2":
            this.flag2 = true;
            this.targetName2 = name;
            id = "seismicId2";
            this.seismicItem2 = {
              id,
              xData,
              yData,
              myChart,
              name,
              flag,
              targetId
            };
            // this.$nextTick(() => {
            //   console.log("获取dom", document.getElementById(id), this.seismicId2);
            //   this.asyncInitCharts(id, xData, yData, this.seismicId2, name);
            // });
            break;
          case "3":
            this.flag3 = true;
            this.targetName3 = name;
            id = "seismicId3";
            this.seismicItem3 = {
              id,
              xData,
              yData,
              myChart,
              name,
              flag,
              targetId
            };
            break;
          case "4":
            this.flag4 = true;
            this.targetName4 = name;
            id = "seismicId4";
            this.seismicItem4 = {
              id,
              xData,
              yData,
              myChart,
              name,
              flag,
              targetId
            };
            break;
          case "5":
            this.flag5 = true;
            this.targetName5 = name;
            id = "seismicId5";
            this.seismicItem5 = {
              id,
              xData,
              yData,
              myChart,
              name,
              flag,
              targetId
            };
            break;
          case "6":
            this.flag6 = true;
            this.targetName6 = name;
            id = "seismicId6";
            this.seismicItem6 = {
              id,
              xData,
              yData,
              myChart,
              name,
              flag,
              targetId
            };
            break;
        }
      }
      // console.log("地震荷载传图表值id", id, document.getElementById(id), "mychart", myChart, "name", name, "flag", flag); //"xdata", xData, "ydata", yData,

      this.$nextTick(() => {
        this.disposeInitCharts();
        // this.asyncInitCharts(id, xData, yData, myChart, name);
      });
    },
    dispose(id) {
      if (document.getElementById(id)) {
        var chart1 = echarts.init(document.getElementById(id));
        // console.log("销毁图表chart1", chart1);
        chart1.dispose();
      }
    },
    disposeInitCharts() {
      // console.log("获取dom", JSON.stringify(this.seismicItem1 != "{}"), this.seismicItem1, JSON.stringify(this.seismicItem2 != "{}"), this.seismicItem2);

      if (this.isMeasure == "1" && this.seismicItem1 && this.seismicItem1.id) {
        this.dispose(this.seismicItem1.id);
        console.log("第1个", this.seismicItem1);
        this.asyncInitCharts(this.seismicItem1.id, this.seismicItem1.xData, this.seismicItem1.yData, this.seismicItem1.myChart, this.seismicItem1.name);
      }

      if (this.isMeasure == "1" && this.seismicItem2 && this.seismicItem2.id) {
        console.log("第2个", this.seismicItem2);
        this.dispose(this.seismicItem2.id);

        this.asyncInitCharts(this.seismicItem2.id, this.seismicItem2.xData, this.seismicItem2.yData, this.seismicItem2.myChart, this.seismicItem2.name);
      }

      if (this.isMeasure == "1" && this.seismicItem3 && this.seismicItem3.id) {
        console.log("第3个", this.seismicItem3);
        this.dispose(this.seismicItem3.id);
        this.asyncInitCharts(this.seismicItem3.id, this.seismicItem3.xData, this.seismicItem3.yData, this.seismicItem3.myChart, this.seismicItem3.name);
      }

      if (this.isMeasure == "2" && this.seismicItem4 && this.seismicItem4.id) {
        console.log("第4个", this.seismicItem4);
        this.dispose(this.seismicItem4.id);
        this.asyncInitCharts(this.seismicItem4.id, this.seismicItem4.xData, this.seismicItem4.yData, this.seismicItem4.myChart, this.seismicItem4.name);
      }

      if (this.isMeasure == "2" && this.seismicItem5 && this.seismicItem5.id) {
        console.log("第5个", this.seismicItem5);
        this.dispose(this.seismicItem5.id);
        this.asyncInitCharts(this.seismicItem5.id, this.seismicItem5.xData, this.seismicItem5.yData, this.seismicItem5.myChart, this.seismicItem5.name);
      }
      if (this.isMeasure == "2" && this.seismicItem6 && this.seismicItem6.id) {
        console.log("第6个", this.seismicItem6);
        this.dispose(this.seismicItem6.id);
        this.asyncInitCharts(this.seismicItem6.id, this.seismicItem6.xData, this.seismicItem6.yData, this.seismicItem6.myChart, this.seismicItem6.name);
      }
    },
    asyncInitCharts(id, xData, yData, myChart, name) {
      // document.getElementById(id).removeAttribute("_echarts_instance_");

      myChart = echarts.init(document.getElementById(id));
      // optionData
      let myoption = JSON.parse(JSON.stringify(option1));
      // console.log("myoption", myoption, xData, yData);
      myoption.series[0].data = yData;
      myoption.series[0].name = name;
      myoption.xAxis.data = xData;
      // myoption.xAxis.type = "time";
      // myoption.yAxis[0].name = this.targetUnit;
      // myoption.yAxis[0].name = name;
      let max = Math.max.apply(null, yData).toFixed(4);
      let min = Math.min.apply(null, yData).toFixed(4);
      myoption.yAxis[0].max = max;
      myoption.yAxis[0].min = min;

      myChart.setOption(myoption);

      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    // initEcharts(id, option) {
    //   this.chartList = item;

    //   this.myChart = echarts.init(document.getElementById(id));
    //   // option.yAxis[0].name = "作用效应" + "(" + this.targetUnit + ")";
    //   this.myChart.setOption(option);
    //   window.addEventListener("resize", () => {
    //     this.myChart.resize();
    //   });
    // },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.win {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 150px !important;
}
::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.tem_l_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
}
.tem_l_img img {
  width: 320px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}
#lut_10_2 {
  position: absolute;
  // z-index: 99;
  // top: 120px !important;
}
.pos-rel {
  position: relative;
}
.radus24 {
  // background: #102048;
  border-radius: 24px;
}

.tem_l {
  margin-left: 10px;
  width: 418px;
  height: 100%;
  margin-right: 16px;
}

.lut_10_class_2 {
  top: -7px;
  left: 26px;
  width: 300px;
  height: 300px;
  position: absolute;
}
::v-deep .el-input {
  height: 32px;
}
.gui_10_class {
  top: 35px;
  right: 5px;
  position: absolute;
}

.helper_10_class_2 {
  bottom: 50px;
  left: 26px;
  width: 128px;
  height: 128px;
  position: absolute;
}

#loading_1 {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -30px;
  z-index: 3;
  display: block;
  width: 60px;
  height: 60px;
  background: url("../../../../assets/images/gif.gif");
  background-size: 100% 100%;
}
.head-load-form {
  // z-index: 50;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.pad26 {
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: border-box;
}
.tem_container_10_class {
  top: 50px;
  left: 20px;
  right: 0px;
  bottom: 20px;
  position: absolute;
  // background: #102048;
  // border-radius: 24px;
}
.container_10_class_2 {
  height: 100%;
  width: 100%;
}

.btnStyle {
  border: 1px solid #2667db;
  text-align: center;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0);
}

.active-bg {
  background-color: #2667db;
}

.pointer {
  cursor: pointer;
}

.el-form-custom .sim-position ::v-deep .el-input {
  width: 100% !important;
}

@media screen and (min-width: 1880px) {
  .el-form-custom .sim-position .el-input {
    width: 100% !important;
  }
}

.load-text {
  width: 810px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -405px;
  bottom: 50px;
}

.text-pos {
  margin-left: -45px;
}
.down-load {
  // margin-bottom: 16px;
  // height: 50px;
}
.down-load-icon {
  padding: 0px 30px 0px 35px;
  // background: url(../../../../assets/images/daochu@2x.png) no-repeat;
  background-size: 20px;
  background-position: center left;
  font-family: SourceHanSansSC-Normal;
  font-weight: 400;
  font-size: 14px;
  color: #32C5FF;
  cursor: pointer;
  position: relative;
  top: 20px;
}

.flex-clumn {
  flex-flow: column;
}
.seismcl-echarts {
  height: 300px;
  margin-bottom: 30px;
  box-sizing: border-box;
  padding: 16px 0px 22px 0px;
  border-bottom: 2px solid #1C3574;

}
.echarts-list {
  height: 100%;
  // width: calc(100vw - 86px);
}
.echarts-list div {
  margin-left: 1%;
}
.echarts-list-item {
  // background: rgba(0, 0, 0, 0.17);
  border-radius: 8px;
}
.echarts-list-title {
  // padding: 16px 0 16px 18px;
  margin-bottom: 15px;
}
.echarts-list-title::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 12px;
  background: #72D6F3;
  opacity: 0.58;
  margin-right: 10px; 
}

.border {
  float: left;
  height: 200;
  // width: 48.5%;
}
.item {
  height: 180px;
}
</style>
