<template>
  <div class="modelIndex fangzhen">
    <!-- 地震荷载   -->

    <div class="mainBox box box_column">
      <div class="box justify padL46" v-if="false">
        <ul class="ul1 box center ulTab">
          <li class="textC pointer box center" v-for="(item, index) in list" :key="index" :class="active == item.id ? 'tab1' : ''" @click="changeTab(item.id)">
            <!-- <div style="width:100%">
                        <div>{{item.name}}</div> -->
            <!-- <div  v-if="active == item.id" class="fu">
                            <img src="../../../assets/images/Rectangle_bar.png" alt="">
                        </div>
                        <div>
                            <img src="" alt="">
                        </div> -->
            <!-- </div> -->
            <div style="width: 100%" class="fu">
              <div>{{ item.name }}</div>
              <div class="tiao" v-if="active == item.id"></div>
            </div>
            <div class="shu" v-if="index != list.length - 1"></div>
          </li>
        </ul>
        <div>
          <!-- <el-button @click="addData" class="mL44 dataQuery">新建方案</el-button> -->
        </div>
      </div>
      <div class="heng" v-if="false"></div>
      <div class="box justify">
        <el-form class="el-form-custom mB20" :model="formData" :inline="true">
          <el-form-item label="分析方式">
            <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="getTargetOptions">
              <el-option v-for="item in typeOptions" :key="item.id" :value="item.id" :label="item.name" />
            </el-select>
          </el-form-item>
          <span v-if="analysis == 1" class="mar-l24">
            <el-form-item label="计算方案">
              <el-select v-model="formData.schemeId" clearable placeholder="请选择">
                <el-option v-for="item in directionOptions" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="纵向测点">
              <el-select v-model="formData.transverseId" clearable placeholder="请选择" @change="() => analysisMethod('1')">
                <el-option v-for="item in transverseOptions" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item label="横向测点">
              <el-select v-model="formData.directionId" clearable placeholder="请选择" @change="() => analysisMethod('2')">
                <el-option v-for="item in directionOptions" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item label="竖向测点">
              <el-select v-model="formData.verticalId" clearable placeholder="请选择" @change="() => analysisMethod('3')">
                <el-option v-for="item in verticalOptions" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item> -->

            <!-- <el-form-item label="分析时刻"> <el-input v-model="formData.code" placeholder="请输入基准温度" style="width: 100px" /><span style="padding-left: 8px">℃</span> </el-form-item> -->
            <el-form-item prop="startTime" label="分析时刻">
              <el-date-picker style="width: 230px" @change="clearTable(1)" placeholder="默认当前时间前1分钟" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="formData.startTime"> </el-date-picker>
            </el-form-item>
            <span class="broken-line">--</span>
            <el-form-item prop="endTime">
              <el-date-picker style="width: 230px" disabled placeholder="默认当前时间" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="formData.endTime"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="calculation" class="pointer" :class="!myOption ? 'disable_icon' : ''">在线计算</el-button>
              <el-button @click="calculationSave" :disabled="saveFlag" class="pointer" :class="!myOption ? 'disable_icon' : ''">保存</el-button>
            </el-form-item>
          </span>
          <span v-if="analysis == 2" class="mar-l24">
            <el-button @click="downLoad" class="pointer" :class="!myUpload ? 'disable_icon' : ''">模板下载</el-button>
            <el-button @click="importModule" class="pointer" :class="!myUpload ? 'disable_icon' : ''">导入</el-button>
          </span>
        </el-form>

        <el-form class="el-form-custom mB20" :inline="true">
          <el-form-item v-if="analysis == 2">
            <el-button @click="calculation" :disabled="analysis == 2" class="pointer" :class="!myOption ? 'disable_icon' : ''">在线计算</el-button>
          </el-form-item>
          <el-form-item v-if="analysis == 1">
            <el-button @click="hisResList" class="pointer" :class="!myOption ? 'disable_icon' : ''">历史结果</el-button>
            <el-button @click="clacPlanHandle" class="pointer" :class="!myOption ? 'disable_icon' : ''">计算方案</el-button>
          </el-form-item>
          <!-- <el-form-item>
          <el-input v-model="formData.code" placeholder="请输入测点编号" />
        </el-form-item> -->
        </el-form>
      </div>
      <div class="flex1 main simulation">
        <!-- <div v-if="active == 1">
          <online></online>
        </div>
        <div v-if="active == 2" style="height: 98%" class="main2">
          <modelAnalysisShow></modelAnalysisShow>
        </div>
        <div v-if="active == 3">
          <plan></plan>
        </div> -->

        <!-- <deadLoad v-show="active == 1" class="model-show"></deadLoad> -->
        <seismicLoadSansheng v-if="projectId == 10032" :active="active" ref="meshSeismic"></seismicLoadSansheng>
        <seismicLoadDawang v-if="projectId == 10046" :active="active" ref="meshSeismic"></seismicLoadDawang>
        <seismicLoadBadusan v-if="projectId == 10034" :active="active" ref="meshSeismic"></seismicLoadBadusan>
        <seismicLoadXiangmihu v-if="projectId == 84" :active="active" ref="meshSeismic"></seismicLoadXiangmihu>
      </div>
    </div>
    <uploadFile v-if="visible" @close="close" :uploadUrl="uploadUrl" @success="success" />
    <calculationPlan v-if="planFlag" @close="closePlan" />
    <calculationRes ref="calcRes" v-if="hisFlag" @close="closeClacRes" :pageNum="pageNum" :total="total" :pageSize="pageSize" :tableData="tableData"></calculationRes>
  </div>
</template>

<script>
import seismicLoad from "./seismicLoad";
import seismicLoadDawang from "./seismicLoad_dawang";
import seismicLoadBadusan from "./seismicLoad_badusan";
import seismicLoadSansheng from "./seismicLoad_sansheng";
import seismicLoadXiangmihu from "./seismicLoad_xiangmihu";
import { seismicOptions } from "../common/mesh";
import uploadFile from "../../../../components/upload";
import calculationPlan from "../calculationPlan";
import calculationRes from "../calculationRes";
// import plan from "./plan";
import moment from "moment";
export default {
  name: "modelIndex",
  components: {
    // online,
    // modelAnalysisShow,
    // plan,
    seismicLoad,
    uploadFile,
    calculationPlan,
    calculationRes,
    seismicLoadDawang,
    seismicLoadBadusan,
    seismicLoadSansheng,
    seismicLoadXiangmihu
  },
  data() {
    return {
      total: 0,
      pageNum: "1",
      pageSize: 10,
      calcRes: {},
      saveFlag: true,
      hisFlag: false,
      planFlag: false,
      loadingPage: null,
      analysis: 1,
      list: [
        { id: "1", name: "自重荷载" },
        { id: "2", name: "温度荷载" },
        { id: "3", name: "地震荷载" }
      ],
      active: "1",
      type: "add",

      //  窗口
      win: 1,
      ruleForm: { startTime: "", endTime: "" },
      open: "0",
      formData: { typeGroupId: "1", transverseId: "", schemeId: "", directionId: "", verticalId: "", startTime: "", endTime: "" },
      transverseOptions: [], // 纵向
      directionOptions: [], // 横向
      verticalOptions: [], // 竖向
      targetOptions: [],
      targetOptions1: [],
      targetOptions2: [],
      typeOptions: seismicOptions,
      projectId: "",
      temp: "",
      temp1: "",
      flag: true,
      visible: false,
      seismicId1: null,
      seismicId2: null,
      seismicId3: null,
      size: 30000,
      uploadUrl: "simulation/import/quake", // 文件上传地址
      clacPlanFlag: false
    };
  },
  //创建
  created() {
    this.projectId = this.$store.state.projectId || sessionStorage.getItem("projectId");
    this.uploadUrl = "simulation/import/quake/"+ this.projectId
    console.log("在线仿真route", this.$route);
    this.active = this.$route.query.active || 1;
    let currentTime = new Date().getTime();
    let day = currentTime - 1 * 60 * 1000;
    this.formData.startTime = moment(day).format("YYYY-MM-DD HH:mm:ss");
    this.formData.endTime = moment(currentTime).format("YYYY-MM-DD HH:mm:ss");
    // this.getCalcRes();
    // this.clearTable();
  },
  activated() {
    console.log("mnnmnm");
  },
  //安装
  mounted() {
    // this.initAllData();
    this.initScheme();
    this.$refs["meshSeismic"].initSeismic();
  },

  //方法
  methods: {
    closeClacRes() {
      this.hisFlag = false;
    },
    // useHisRes() {
    //   this.getChartsData()
    // },
    clacPlanHandle() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.planFlag = true;
    },
    hisResList() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.hisFlag = true;
      // this.$refs["calcRes"].dialogVisible = true;
    },
    calculationSave() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$axios
        .post(`${this.baseURL}simulation/add/quake/result`, this.calcRes)
        .then((res) => {
          console.log("方案", res);
          if (res.data.errCode === "200") {
            // this.$bus.$emit("caluResult", res);
            this.$message.success("保存成功！");
            this.saveFlag = true;
            this.getTableData("save");
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    getTableData(save) {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      this.$axios
        .get(`${this.baseURL}simulation/page/quake/result/${this.projectId}`, { params })
        .then((res) => {
          console.log("计算历史结果====", res, save);
          if (res.data.errCode === "200") {
            // this.$bus.$emit("caluResult", res);
            if (!save || save != "save") {
              let flag = false;
              if (res.data.data.records && res.data.data.records.length) {
                for (let i = 0; i < this.directionOptions.length; i++) {
                  if (res.data.data.records[0].schemeId == this.directionOptions[i].id) {
                    this.getChartsData(res.data.data.records[0]);
                    flag = true;
                    break;
                  }
                }
              }
            }
            this.hisFlag = false;
            this.tableData = res.data.data.records;
            console.log(" this.tableData = res.data.data.records;", this.tableData);
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    close() {
      this.visible = false;
    },
    closePlan() {
      this.planFlag = false;
      this.initScheme("close");
    },
    success(e) {
      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeee", e);
      this.close();
      if (e && e.data) {
        console.log("ceshilog");

        this.$refs["meshSeismic"].caluResult(e);

        this.setChartsData(e, "clac");
      }
    },

    initScheme(flag) {
      //  /simulation/quake/scheme
      this.$axios
        .get(`${this.baseURL}simulation/quake/scheme/${this.projectId}`)
        .then((res) => {
          console.log("方案", res);
          if (res.data.errCode === "200") {
            // this.$bus.$emit("caluResult", res);
            // this.formData.schemeId = res.data.data[0].id;
            this.directionOptions = res.data.data;

            if (!flag) {
              this.getTableData();
              // this.getChartsData();
              // this.calculation();
            }
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    getChartsData(item) {
      console.log("item", item);
      let params = {};
      if (item && item.projectId) {
        this.formData = { ...this.formData, ...item };
        // this.formData["schemeId"] = item.schemeName;
        params = item;
        params["size"] = this.size;
        let res = { data: item };
        console.log("ceshilog");
        this.$refs["meshSeismic"].caluResult(res);
      } else {
        params = {
          endTime: this.formData.endTime,
          projectId: this.projectId,
          schemeId: this.formData.schemeId,
          size: this.size,
          startTime: this.formData.startTime
        };
      }

      this.$axios
        .get(`${this.baseURL}simulation/data`, { params })
        .then((res) => {
          console.log("图表数据", res);
          if (res.data.errCode === "200") {
            // this.seismicId2
            this.setChartsData(res.data);
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    setChartsData(res, clac = "") {
      this.$refs.meshSeismic.initEcharts([], [], this.seismicId1, "", "1");
      this.$refs.meshSeismic.initEcharts([], [], this.seismicId2, "", "2");
      this.$refs.meshSeismic.initEcharts([], [], this.seismicId3, "", "3");
      if (res.data.x.length) {
        // this.$refs.meshSeismic.initEcharts([], [], this.seismicId1, "", "1");
        let xData = [],
          yData = [];
        res.data.x.forEach((item) => {
          // console.log("item", item);
          if (clac) {
            xData.push(item.time);
          } else {
            xData.push(moment(new Date(Number(item.time))).format("YYYY-MM-DD HH:mm:ss"));
          }
          yData.push(item.data);
        });
        this.$refs.meshSeismic.initEcharts(xData, yData, this.seismicId1, res.data.xcode, "1", this.size, { startTime: this.formData.startTime + ":00:00", endTime: this.formData.endTime }, { schemeId: this.formData.schemeId, projectId: this.projectId });
      }
      if (res.data.y.length) {
        // this.$refs.meshSeismic.initEcharts([], [], this.seismicId2, "", "2");
        let xData = [],
          yData = [];
        res.data.y.forEach((item) => {
          if (clac) {
            xData.push(item.time);
          } else {
            xData.push(moment(new Date(Number(item.time))).format("YYYY-MM-DD HH:mm:ss"));
          }
          yData.push(item.data);
        });
        this.$refs.meshSeismic.initEcharts(xData, yData, this.seismicId2, res.data.ycode, "2", this.size, { startTime: this.formData.startTime + ":00:00", endTime: this.formData.endTime }, { schemeId: this.formData.schemeId, projectId: this.projectId });
      }
      if (res.data.z.length) {
        // this.$refs.meshSeismic.initEcharts([], [], this.seismicId3, "", "3");
        let xData = [],
          yData = [];
        res.data.z.forEach((item) => {
          if (clac) {
            xData.push(item.time);
          } else {
            xData.push(moment(new Date(Number(item.time))).format("YYYY-MM-DD HH:mm:ss"));
          }
          yData.push(item.data);
        });
        this.$refs.meshSeismic.initEcharts(xData, yData, this.seismicId3, res.data.zcode, "3", this.size, { startTime: this.formData.startTime + ":00:00", endTime: this.formData.endTime }, { schemeId: this.formData.schemeId, projectId: this.projectId });
      }
    },
    importModule() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      this.visible = true;
    },
    downLoad() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }

      // 模板下载
      let params = {};
      this.$axios
        .get(`${this.baseURL}simulation/download`, { responseType: "blob", params })
        .then((res) => {
          console.log("模板下载", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", "模板.xlsx");
          a.click();
        })
        .catch((err) => {});
    },
    importLoad() {},
    getTargetOptions(e) {
      console.log("fenxicedian1111", e);
      this.analysis = e;
      // this.$refs.meshSeismic.artificToMeasure(e);
      this.$refs.meshSeismic.initEcharts([], [], this.seismicId1, "", "1");
      this.$refs.meshSeismic.initEcharts([], [], this.seismicId2, "", "2");
      this.$refs.meshSeismic.initEcharts([], [], this.seismicId3, "", "3");
      if (e == 1) {
        this.formData.schemeId = this.formData.OschemeId;

        this.getChartsData();
        this.calculation();
      } else {
        this.formData.OschemeId = this.formData.schemeId;
        this.formData.schemeId = "";
      }
    },
    initAllData() {
      this.getSeismicAnalysis("1");
      this.getSeismicAnalysis("2");
      this.getSeismicAnalysis("3");
    },
    analysisMethod(flag) {
      console.log("fenxicedian", this.formData.transverseId, "竖向", this.formData.verticalId, "横向", this.formData.directionId, "flag", flag);

      switch (flag) {
        case "1":
          if (this.formData.transverseId) {
            let n = this.transverseOptions.length;
            for (let s = 0; s < n; s++) {
              if (this.transverseOptions[s].id === this.formData.transverseId) {
                this.getHisData(this.formData.transverseId, "1", this.seismicId1, this.transverseOptions[s].name);
                return;
              }
            }
          }
          this.getHisData(this.formData.transverseId, "1", this.seismicId1, "");

          break;
        case "3":
          if (this.formData.verticalId) {
            let n = this.verticalOptions.length;
            for (let s = 0; s < n; s++) {
              if (this.verticalOptions[s].id === this.formData.verticalId) {
                this.getHisData(this.formData.verticalId, "3", this.seismicId3, this.verticalOptions[s].name);
                return;
              }
            }
          }
          this.getHisData(this.formData.verticalId, "3", this.seismicId3, "");
          break;
        case "2":
          if (this.formData.directionId) {
            let n = this.directionOptions.length;
            for (let s = 0; s < n; s++) {
              if (this.directionOptions[s].id === this.formData.directionId) {
                this.getHisData(this.formData.directionId, "2", this.seismicId2, this.directionOptions[s].name);
                return;
              }
            }
          }
          this.getHisData(this.formData.directionId, "2", this.seismicId2, "");
          break;
      }
    },

    clearTable(e) {
      console.log("分析时刻", e, this.formData.startTime);

      var day = new Date(this.formData.startTime);
      console.log("day", day);
      var day1 = day.getTime(); //获得当前时间戳
      var day2 = day1 + 60 * 1000;

      let date5 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      console.log("date5", date5);
      this.formData.endTime = date5;
      this.getChartsData();
      // if (this.formData.directionId) {
      //   this.analysisMethod("2");
      // }
      // if (this.formData.verticalId) {
      //   this.analysisMethod("3");
      // }

      // if (this.formData.transverseId) {
      //   this.analysisMethod("1");
      // }
    },
    // 计算
    calculation() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      // 1 整体温度效应  温度-基准温度   2 温度梯度  T1-T2  （T1-T2）*T2系数
      // let data;
      // if (this.analysis == 1) {
      //   let tem = this.formData.temperature - this.formData.baseTem;
      //   data = {
      //     type: this.formData.type,
      //     temp: tem.toString()
      //   };
      // }
      // if (this.analysis == 2) {
      //   data = {
      //     type: this.formData.type,
      //     temp: this.formData.temDiff.toString()
      //   };
      // }
      // if (this.analysis == 3) {
      //   let tem1 = this.formData.temperature1 - this.formData.baseTem2;
      //   let tem2 = (this.formData.temperature2 - this.formData.baseTem2) * this.formData.coefficient2;
      //   data = {
      //     type: this.formData.type,
      //     temp: tem1 + "," + tem2
      //   };
      // }

      if (this.analysis == 2) return;
      console.log("formdata", this.formData);

      this.loadingPage = this.$loading({
        lock: true,
        text: "正在计算...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      let params = {
        projectId: this.projectId,
        size: this.size,
        ...this.formData
      };
      this.$axios
        .get(`${this.baseURL}simulation/quake`, { params })
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            console.log("计算结果数据", res);
            // this.$bus.$emit("caluResult", res);
            this.saveFlag = false;
            this.calcRes = res.data;
            this.flag = false;
        console.log("ceshilog");

            this.$refs["meshSeismic"].caluResult(res);
            this.getChartsData(1)
          } else {
            this.$message.error(res.errMsg);
          }
          this.loadingPage.close();
        })
        .catch((err) => {
          this.loadingPage.close();
        });
    },
    changeTab(id) {
      console.log("切换id", id);
      this.active = id;
      this.$refs["meshSeismic"].active = id;
      this.$router.push({ path: "/simulation?active=" + id });
    },

    // 获取测点列表
    getSeismicAnalysis(seismic) {
      let params = {
        projectId: this.projectId,
        type: seismic
      };

      this.$axios.get(`${this.baseURL}simulation/type/rel`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("res", res);

          switch (seismic) {
            case "1":
              this.transverseOptions = res.data || [];
              if (this.transverseOptions.length) {
                this.formData.transverseId = this.transverseOptions[0].id;

                this.getHisData(this.formData.transverseId, "1", this.seismicId1, this.transverseOptions[0].name);
              } else {
                this.formData.transverseId = "";

                this.getHisData(this.formData.transverseId, "1", this.seismicId1, "");
              }
              break;
            case "2":
              this.directionOptions = res.data || [];
              if (this.directionOptions.length) {
                this.formData.directionId = this.directionOptions[0].id;

                this.getHisData(this.formData.directionId, "2", this.seismicId2, this.directionOptions[0].name);
              } else {
                this.formData.directionId = "";

                this.getHisData(this.formData.directionId, "2", this.seismicId2, "");
              }
              break;
            case "3":
              this.verticalOptions = res.data || [];
              if (this.verticalOptions.length) {
                this.formData.verticalId = this.verticalOptions[0].id;

                this.getHisData(this.formData.verticalId, "3", this.seismicId3, this.verticalOptions[0].name);
              } else {
                this.formData.verticalId = "";

                this.getHisData(this.formData.verticalId, "3", this.seismicId3, "");
              }
              break;
          }

          console.log("this.seismicId3", this.seismicId3, "this.seismicId2", this.seismicId2, "this.seismicId1", this.seismicId1);
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },

    //  根据测点  时间 获取图表数据 // 原始数据

    getHisData(targetId, flag, myChart, targetName) {
      if (!targetId) {
        this.$refs.meshSeismic.initEcharts([], [], myChart, "", flag);
        return;
      }

      let params = {
        targetId: targetId,
        sort: 1,
        startTime: this.formData.startTime + ":00:00",
        endTime: this.formData.endTime,
        size: this.size
      };
      console.log("参数", params);
      this.$axios.get(`${this.baseURL}data/history/raw/${targetId}`, { params }).then((res) => {
        res = res.data;
        console.log("原始数据", res);
        let data = res.data;
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          for (let item of data) {
            xData.unshift(item.time);
            yData.unshift(item.data[0]);
          }
          // xData=['2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56','2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56']
          // yData=[90, 30, 70, 50, 20, 100, 12,33, 44, 55, 66, 77, 88, 90]
          this.$refs.meshSeismic.initEcharts(xData, yData, myChart, targetName, flag, this.size, { startTime: this.formData.startTime + ":00:00", endTime: this.formData.endTime }, targetId);
        } else {
          this.$message.error(res.errMsg);
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.model-show {
}
.mar-l24 {
  margin-left: 24px;
}
.modelIndex {
  width: 100%;
  height: 100%;

  // border: 1px solid  red;
  // overflow-y: hidden;
  .ul1 {
    margin-bottom: 20px;

    li {
      // width: 140px;
      // padding: 0 20px;
      color: #ccdaff;
      // border-right:  1px solid #3A4A7B;
    }

    li:last-child {
      border-right: none;
    }
  }

  .tab1 {
    color: #fff !important;
  }

  // .fu{
  //     position: relative;
  //     width: 100%;
  //     top: -5px;
  //     img{
  //         width: 100%;
  //     }
  // }
  .heng {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    box-shadow: 0px -1px 4px 0px #ffffff;
    opacity: 0.32;
    border: 1px solid #1f61ff;
  }
}

.pad46 {
  padding-left: 46px;
  padding-right: 46px;
  box-sizing: border-box;
}

.mainBox {
  height: 100%;
}

.main {
  height: 100%;
  overflow: hidden;
}

.padL46 {
  padding-left: 46px;
  box-sizing: border-box;
}
.padR46 {
  padding-right: 46px;
  box-sizing: border-box;
}
.simulation {
  // padding-top: 34px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  width: 100%;
  background: #04153f;
  border: 1px solid #0C3380;
  position: relative;
}
.main2 {
}

.ulTab {
  .fu {
    position: relative;
  }

  li {
    position: relative;
    cursor: pointer;
  }

  .tiao {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 8px;
    background: url("../../../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }

  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #ccc;
  }
}

.broken-line {
  display: inline-block;
  line-height: 32px;
  height: 100%;
}

.fangzhen .el-form-custom ::v-deep .el-input.is-disabled .el-input__inner {
  border-color: none !important;
}
</style>
