<template>
  <el-dialog title="方案查询" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
    <div>
      <el-button @click="addPlan" class="pointer plan-check">新建方案</el-button>
    </div>
    <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" prop="name" label="方案名称"></el-table-column>
      <el-table-column align="center" prop="" label="测点名称">
        <template slot-scope="scope">
          <div v-if="scope.row.xtargetCode">纵向测点{{ scope.row.xtargetCode }}</div>
          <div v-if="scope.row.ytargetCode">横向测点{{ scope.row.ytargetCode }}</div>
          <div v-if="scope.row.ztargetCode">竖向测点{{ scope.row.ztargetCode }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="操作">
        <template slot-scope="scope">
          <span @click="look(scope.row, 'edit')" class="pointer operate-txt">编辑</span>
          <span @click="look(scope.row, 'del')" class="pointer operate-txt">删除</span>
        </template>
      </el-table-column>
      <template slot="empty">
        <span class="iconfont iconzanwushuju-"></span>
        <span>暂无数据</span>
      </template>
    </el-table>
    <el-dialog width="500px" :title="title" :visible.sync="innerVisible" append-to-body>
      <el-form class="el-form-custom mB20 plan" :model="formData" :inline="false" label-width="100px" label-position="left">
        <el-form-item label="方案名称">
          <el-input v-model="formData.name" style="width: 300px; height: 32px"></el-input>
        </el-form-item>
        <el-form-item label="纵向测点">
          <el-select v-model="formData.XTargetId" placeholder="请选择">
            <el-option v-for="item in XTarget" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
          <span> <span style="color: #fff; padding: 0 10px">系数</span> <el-input v-model="formData.XCoefficient" style="width: 100px; height: 32px"></el-input> </span>
        </el-form-item>

        <el-form-item label="横向测点">
          <el-select v-model="formData.YTargetId" clearable placeholder="请选择">
            <el-option v-for="item in YTarget" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
          <span> <span style="color: #fff; padding: 0 10px">系数</span> <el-input v-model="formData.YCoefficient" style="width: 100px; height: 32px"></el-input> </span>
        </el-form-item>

        <el-form-item label="竖向测点">
          <el-select v-model="formData.ZTargetId" clearable placeholder="请选择">
            <el-option v-for="item in ZTarget" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
          <span> <span style="color: #fff; padding: 0 10px">系数</span> <el-input v-model="formData.ZCoefficient" style="width: 100px; height: 32px"></el-input> </span>
        </el-form-item>
        <div class="sei-btn">
          <el-form-item class="btns mT20">
            <el-button class="btn back-btn" @click="innerVisible = false">取消</el-button>
            <el-button class="btn submit-btn" @click="submitForm">保存</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
    <div class="sei-btn">
      <div>
        <el-button class="btn back-btn" @click="handleClose">关闭</el-button>
        <!-- <el-button class="btn submit-btn" @click="submitForm">保存</el-button> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      innerVisible: false,
      tableData: [],
      loading: false,
      dialogVisible: true,
      projectId: "",
      formData: {
        name: "",
        projectId: "",
        XCoefficient: "",
        XTargetId: "",
        YCoefficient: "",
        YTargetId: "",
        ZCoefficient: "",
        ZTargetId: ""
      },
      XTarget: [],
      YTarget: [],
      ZTarget: [],
      title: ""
    };
  },
  created() {
    this.projectId = this.$store.state.projectId || sessionStorage.getItem("projectId");
    this.getTableData();
    this.getTargetList(1, this.XTarget);
    this.getTargetList(2, this.YTarget);
    this.getTargetList(3, this.ZTarget);
  },
  methods: {
    submitForm() {
      if (!this.formData.name) {
        this.$message.error("请填写方案名称！");
        return;
      }
      if (this.formData.XTargetId == "" && this.formData.YTargetId == "" && this.formData.ZTargetId == "") {
        this.$message.error("请选择测点");
        return;
      }
      this.formData["projectId"] = this.projectId;
      this.$axios
        .post(`${this.baseURL}simulation/quake/scheme`, this.$qs.stringify(this.formData))
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            // this.$bus.$emit("caluResult", res);
            this.$message.success("操作成功！");
            this.innerVisible = false;

            this.getTableData();
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    getTargetList(type, item) {
      let params = {
        projectId: this.projectId,
        type
      };
      this.$axios
        .get(`${this.baseURL}simulation/type/rel`, { params })
        .then((res) => {
          console.log("方案XTarget", res, item);
          res = res.data;
          if (res.errCode === "200") {
            // this.$bus.$emit("caluResult", res);
            switch (type) {
              case 1:
                this.XTarget = res.data;
                break;
              case 2:
                this.YTarget = res.data;
                break;
              case 3:
                this.ZTarget = res.data;
                break;
            }
            // item = res.data;

            console.log("this.XTarget", this.XTarget, "type", type);
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    addPlan() {
      this.title = "新建方案";
      for (const i in this.formData) {
        this.formData[i] = "";
      }
      this.innerVisible = true;
    },
    handleClose() {
      this.$emit("close");
    },
    look(item, flag) {
      switch (flag) {
        case "edit":
          this.title = "编辑方案";

          this.formData["id"] = item.id;
          this.formData["name"] = item.name;
          this.formData["XTargetId"] = item.xtargetId;
          this.formData["YTargetId"] = item.ytargetId;
          this.formData["ZTargetId"] = item.ztargetId;
          this.formData["XCoefficient"] = item.xcoefficient;
          this.formData["YCoefficient"] = item.ycoefficient;
          this.formData["ZCoefficient"] = item.zcoefficient;

          console.log("formdata", this.formData);
          this.innerVisible = true;
          break;
        case "del":
          this.$confirm("确认删除吗？", "确认删除", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            cancelButtonClass: "btn-cancel",
            confirmButtonClass: "btn-confirm"
          })
            .then(() => {
              console.log("confirm");
              this.delPlan(item.id);
            })
            .catch(() => {
              console.log("取消操作");
            });
          break;
      }
    },
    delPlan(id) {
      this.$axios
        .delete(`${this.baseURL}simulation/quake/scheme/${id}`)
        .then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            // this.$bus.$emit("caluResult", res);
            this.$message.success("删除成功！");
            this.getTableData();
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    getTableData() {
      console.log("tabledata=======================");
      this.$axios
        .get(`${this.baseURL}simulation/quake/scheme/${this.projectId}`)
        .then((res) => {
          console.log("方案", res);
          res = res.data;
          if (res.errCode === "200") {
            // this.$bus.$emit("caluResult", res);
            this.tableData = res.data;
          } else {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    }
  }
};
</script>
<style scoped lang="scss">
.plan-check {
  float: right;
  margin-bottom: 10px;
  width: auto !important;
}
.operate-txt + .operate-txt {
  margin-left: 15px;
}
.sei-btn {
  padding: 15px 0;
  text-align: center;
  display: flex;
  justify-content: center;
}
.plan ::v-deep .el-form-item:first-child .el-form-item__label {
  padding-left: 26px;
}
// .btn-wrapper {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   // padding-right: 40px;
// }

// .btn-wrapper span {
//   flex: 1;
//   text-align: center;
// }

// ::v-deep .el-table th.el-table__cell > .option-btn.cell {
//   padding-right: 50px;
//   box-sizing: border-box;
// }

// .down-img {
//   width: 100%;
// }

// .main ::v-deep .el-dialog__wrapper div.pdf-class {
//   width: 1200px !important;
//   margin-top: 0px !important;
//   max-height: calc(100vh - 8px);
//   overflow: auto;
//   margin: auto;

//   .pdf-wrapper {
//     box-sizing: border-box;
//     text-align: center;

//     img {
//       margin: auto;
//     }

//     .pdf-btm {
//       padding-top: 20px;
//       text-align: center;
//     }

//     .pdf-title {
//       padding-bottom: 15px;
//       text-align: center;
//     }
//   }
// }

// .main {
//   margin-top: 17px;
//   height: 100%;

//   .pl24 {
//     padding-left: 24px;
//   }

//   .mb24 {
//     margin-bottom: 24px;
//   }

//   .el-table {
//     .iconfont {
//       font-size: 22px;
//     }
//   }

//   .pageMain {
//     margin-top: 15px;
//   }

//   .search {
//     ::v-deep .el-input {
//       width: 230px;
//       height: 32px;
//       margin-right: 20px;

//       .el-icon-search:before {
//         font-size: 22px;
//         color: #2667db;
//       }

//       .el-input__suffix {
//         right: 12px;
//       }

//       .el-input__inner {
//         padding-right: 32px;
//       }
//     }
//   }
// }

// ::v-deep .el-button {
//   // height: 35px !important;
// }

// ::v-deep .el-input {
//   height: 35px !important;
// }

// .back-btn {
//   text-align: center;
//   display: inline-block;
//   // width: 100px;
//   // height: 32px;
//   // line-height: 32px;
// }

// .submit-btn {
//   text-align: center;
//   display: inline-block;
//   // width: 100px;
//   // height: 32px;
//   //  line-height: 32px;
// }

// ::v-deep .el-table__empty-text {
//   margin-top: 10%;
// }

// .imgver {
//   width: 60px;
//   height: 55px;
//   margin: 0 auto;
// }

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
